import React from 'react';
import { toast } from 'react-toastify';
import {useState, useEffect} from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";

import Api from "../helpers/api";
import styled from 'styled-components';
import {StepList} from '../components/steplist';
import {modalStore, useCompanyStore, useInterfaceStore, workflowStore} from '../store';

import {
    SectionTitle, 
    Toolbar,
    ToolbarButton,
    InlineButton,
    ButtonsSection,
    Form, 
    ListWrapper,
    ListItem,
    ListItemCel, 
    ListItemLabel, 
    Input, 
    Description,
} from '../ui_components/general';

export const WorkflowModal = () => {
    
    const navigate = useNavigate();
    const [fadein, setFadein] = useState('fade-in');
    const [name, setName] = useState('');
    const { setModalState } = modalStore(); 
    const {activeWorkflow, workflows, setActiveWorkflow, addWorkflow } = workflowStore(); 

    const closeModal = () => {
     //    runTask();       
        setModalState({showModal: false});
    }
    const handleChange = (event) => {
        if (event.target.name == 'name') {
            setName(event.target.value);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (name){
            let data = {
                'name' : name,
            };
            const endpoint = 'workflows/';
            let response = await Api.getData(endpoint, data, 'post').then( async (response) => {
                if (response.data.success) {
                    const endpoint = `workflows/${response.data.id}`
                    let workflow = await Api.getData(endpoint, [], 'get').then((response) => {
                        addWorkflow(response.data);
                        setActiveWorkflow(response.data.id);
                        navigate(`/workflow/${response.data.id}`);
                        setModalState({
                            showModal: false,
                        });
                    });
                    // refresh form
                    // set Modal to add steps to workflow
                }
            }).catch(async(error) => {
                console.log(error);
            });
        }
    }


    return (
        <div className={fadein +" modal-content"}>
            <div className="step-wrapper">
                <SectionTitle>Geef je Workflow een naam</SectionTitle>
                <Form id="workflow_form" onSubmit={(event) => { handleSubmit(event)}}>
                    <ListItemCel>
                        <ListItemLabel>Naam</ListItemLabel>
                        <Input 
                            onChange= {(event) => handleChange(event)}
                            onFocusOut = {(event) => handleChange(event)}
                    
                            name="name" 
                            value= {name}
                        />
                    </ListItemCel>
                </Form>
                <ButtonsSection>
                    <ToolbarButton className="align-right" onClick={(event) => closeModal()}>Annuleren</ToolbarButton>
                    <ToolbarButton form="workflow_form" className="align-right">Opslaan</ToolbarButton>
                </ButtonsSection>
            </div> 
        </div>
    )
}
