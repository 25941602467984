import React from 'react';
import { toast } from 'react-toastify';
import {useState, useEffect} from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";

import Api from "../helpers/api";
import styled from 'styled-components';
import {StepList} from '../components/steplist';
import {TaskList} from '../components/tasklist';
import {TaskView} from '../components/taskview';
import {GetWorkflows} from '../components/workflowlist';

import {Editor} from '../components/htmleditor/htmleditor';

import {workflowStore, taskStore, promptStore, useLocationStore} from '../store';
import {modalStore, useCompanyStore, useInterfaceStore} from '../store';

import { Nl2br } from '../helpers/format';

import {
    SectionTitle, 
    AreaTitle,
    ListItemCel, 
    ListWrapper,
    ListItem, 
    ListItemFit, 
    ListItemLabel, 
    Form, 
    Input, 
    InputWrapper,
    AskButton,
    Toolbar,
    ToolbarButton,
    InlineButton,
    ButtonsSection,
    ButtonsEndSection,
    Description,
} from '../ui_components/general';


export const ModalContent = ({step, url, task, steps, next_step_function, current_step, action='create' }) => {
    /* @TODO should be moved to a more global location as this is used from both the workflow file as this task modal */   
    const [inputs, setInputs] = useState((step.input) ?? {});
    const [files, setFiles] = useState([]);
    const [fadein, setFadein] = useState('fade-in');
    const [needsSave, setNeedsSave] = useState(false);
    const { setModalState } = modalStore(); 
    const { prompts, addPrompts} = promptStore();
    const {indexLocations} = useLocationStore();
    const navigate = useNavigate();
    const sleep = ms => new Promise(r => setTimeout(r, ms));

    const handleChange = (event, key) => {
        // check if input is file input 

        let newInputs  = [...inputs];
        if (event.target.type == 'file') {
            let newFiles = [...files];
            newFiles.push(event.target.files[0]);
            setFiles(newFiles)   
            
            let newInputs  = [...inputs];
            newInputs[key]['value'] = event.target.files[0].name;
        } else {
            newInputs[key]['value'] = event.target.value;
        }
        setInputs(newInputs);
        setNeedsSave(true);
    }

    const runStep = async () => {
        
        let endpoint = `tasks/${task.id}/steps/execute/${step.id}`;
        let result = await Api.getData(endpoint, [], 'get').then( async (response) => {
            toast.info('Stap wordt uitgevoerd');
            await sleep(5000);
            toast.success('Stap is afgerond');
            // refresh();

        }).catch(async (error)  => {
            if (error.response.status == 401) {
                let result = await Api.refreshToken().then((succes) => {
                    if (succes) {
                        // retry request
                        runStep();
                    } else {
                        // navigate to login
                        navigate('/login');
                    }
                }).catch((error) => {
                    // navigate to login
                    navigate('/login');
                });
            } else {
                console.log('different error? ', error);
            }
        });
    }

    useEffect( () => {
        setInputs(step.input);
        setFiles([]);
        setNeedsSave(false);
        setFadein('fade-in');
    }, [step]);

    const nextStep = async () => {
        setFadein('');
        let input_data = [...inputs];
        let step_data = {
            task_id: task.id, 
            type: step.type,
            name: step.name,
            input: input_data,
            instruction: step.instruction
        }
        if (needsSave 
        || action == 'create'
        ) {

        let result = await Api.getData(url, step_data, 'post').then(async (response) => {
            // upload files 
            if(response.data.success 
                && files.length > 0
            ) {
                const endpoint = url + response.data.id+'/addfile/';
                files.map(async (file, index) => {
                    let formData = new FormData();
                    formData.append('file', file);
                    await Api.getData(endpoint, formData, 'file').then( (response) => {
                        if (index + 1 == files.length) {
                            // last file in list
                            next_step_function(task, steps, current_step);

                        }
                    });
                });
            } else {
                next_step_function(task, steps, current_step);
            }
        }).catch (async (error) => {

        });
        } else {
            next_step_function(task, steps, current_step);
        }
    }

    const renderButtonSection = () => {
        if (action == 'create') {
            return (
                    <ButtonsSection>
                        <ToolbarButton className="align-right" onClick={(event) => nextStep() }>Volgende Stap</ToolbarButton>
                    </ButtonsSection>
            )
        } else if (action == 'edit') {
            return (
                    <ButtonsSection>
                        <ToolbarButton className="cancel" onClick={(event) =>  setModalState({showModal: false}) }>Annuleren</ToolbarButton>
                        <div></div>
                        <ToolbarButton className="align-right" onClick={(event) => runStep() }>Uitvoeren</ToolbarButton>
                        <ToolbarButton className="align-right" onClick={(event) => nextStep() }>Opslaan</ToolbarButton>
                        <ToolbarButton className="align-right" onClick={(event) => nextStep() }>Volgende Stap</ToolbarButton>
                    </ButtonsSection>
            )
        }
    }
    const JsonListItem = ({string}) => {
        return (
            <li><InlineButton onClick={(event) => {navigator.clipboard.writeText(string)}}>Resultaat kopieren</InlineButton><div className="resultstring">{string}</div></li>
        )
    }
    const RenderResultsList = ({json_object, title}) => {
        if (typeof json_object == 'string' 
        || typeof json_object == 'number'
        || typeof json_object == 'boolean'
        ) {
            return (
                <JsonListItem string = {JSON.stringify(json_object)} />
            )
        } else {
                let entries = Object.entries(json_object);
            return (
                    entries.map( ([key, val] ) => {
                        if (typeof val == 'string') {
                            return <ul className="resultslist" aria-label={key}><RenderResultsList key={key} title={key} json_object={val} /></ul>; 
                        } else if (typeof val == 'number'
                        || typeof val == 'boolean'
                        ) {
                            return <ul className="resultslist"><RenderResultsList key={key} title={key} json_object={val} /></ul>; 
                        } else {
                            return <RenderResultsList key={key} title={key} json_object={val} />
                        }
                })    
            );
        }
    }
    const RenderResultText = ({result}) => {
        return (
            <div className="result-text" dangerouslySetInnerHTML={renderHtml(result)} />
        )
    }

    const renderHtml = (text) => {
        return {__html: new Nl2br().nl2br(text, true) }
    
    }
    
    const getPDF = async () => {
        // get PDF from api 
        // render in new frame
        let url = `tasks/${step.task_id}/steps/${step.id}/get_pdf_url/`;
        let result = await Api.getData(url, [], 'get').then(async (response) => {
            // generate pdf, get download url with token
            // then open pdf like a file
                if (response.data.success) {
                    window.open(Api.base_url + response.data.url, '_blank');
                }
        }).catch (async (error) => {
                console.log(error);
        });
    }

    const renderResults = () => {
        if (step.result) {
            if (step.type == 'generate_pdf') {
                // render html editor    
                return (
                    <>
                    <Editor html_string={step.result} />

                    <ButtonsEndSection>
                        <span></span>
                        <InlineButton onClick={(event) => {getPDF()}}>Genereer PDF</InlineButton>
                    </ButtonsEndSection>
                    </>
                )
            } else {
                try {
                    let result = JSON.parse(step.result);
                    return (
                        <>
                        <AreaTitle>Resultaat</AreaTitle>        
                        <Description><ul>
                            <RenderResultsList json_object={result} title="root" />
                        </ul></Description>
                    </>
                    )

                } catch(error) {
                    // console.log(error);
                    return(
                    <>
                        <AreaTitle>Resultaat</AreaTitle>        
                        <Description>
                            <RenderResultText result={step.result} />
                        </Description>
                    </>
                    )
                } 
            }
        } 
    }

    const file_pickers = React.useRef([]);
    const showFilePicker = React.useCallback((index) => {
        file_pickers[index].click();
    }, []);

    const renderInput = (input, index) => {
        if (input.type == 'file') {
            return (
                <div className="input-wrapper">
                    <InlineButton onClick={(event) => showFilePicker(index) } className="file-picker-button">{(input.value ? input.value : 'Bestand selecteren')}</InlineButton>
                    <div className="hidden">
                        <Input
                            ref={(el) => (file_pickers[index] = el)}
                            onChange={(event) => handleChange(event, index)}
                            name={input.label}
                            type="file"
                            value=""
                        />
                    </div>
                </div>
            )    
        
        } else if (input.type == 'tone_of_voice') {
            {/* @TODO get all prompts for company from API use them as select options*/}
            return (
                <select value={input.value} onChange = {(event) => handleChange(event, index)} name={input.label} >

                    <option value="">Maak een keuze</option>
                    {prompts && prompts.map((prompt) => (
                        <option value={prompt.id}>{prompt.name}</option>
                    ))}
                </select>
            )
        } else if (input.type == 'indexlocation') {
            {/* @TODO get all prompts for company from API use them as select options*/}
            return (
                <select value={input.value} onChange = {(event) => handleChange(event, index)} name={input.label} >
                    
                    <option value="">Maak een keuze</option>
                    {indexLocations && indexLocations.map((indexLocation) => (
                        <option value={indexLocation.id}>{indexLocation.name}</option>
                    ))}
                </select>
            )
        } else {
            return (
                <Input
                    onChange = {(event) => handleChange(event, index)}
                    name={input.name}
                    type="text"
                    value={input.value}
                />
            )
        }
    }
    
    if (step.status == 'finished') {
        return (
            <div className={fadein +" modal-content"}>
                <div className="step-wrapper">
                    <SectionTitle>{step.name}</SectionTitle>
                    {renderResults()}
                    <AreaTitle>Instructie</AreaTitle>        
                    <Description dangerouslySetInnerHTML={renderHtml(step.instruction)} />
                    <ListWrapper>
                        {inputs && inputs.map((i, index) => (
                            <ListItemFit key={index}>
                                <InputWrapper>
                                    <ListItemLabel>{i.label}</ListItemLabel>
                                    {renderInput(i, index)}
                                </InputWrapper>
                            </ListItemFit>
                        ))}
                    </ListWrapper>
                    {renderButtonSection()}
                </div> 
            </div>
        )

    } else {
        return (
            <div className={fadein +" modal-content"}>
                <div className="step-wrapper">
                    <SectionTitle>{step.name}</SectionTitle>
                    <AreaTitle>Instructie</AreaTitle>        
                    <Description dangerouslySetInnerHTML={renderHtml(step.instruction)} />
                    {renderResults()}
                    <ListWrapper>
                        {inputs && inputs.map((i, index) => (
                            <ListItem key={index}>
                                <InputWrapper>
                                    <ListItemLabel>{i.label}</ListItemLabel>
                                    {renderInput(i, index)}
                                </InputWrapper>
                            </ListItem>
                        ))}
                    </ListWrapper>
                    {renderButtonSection()}
                </div> 
            </div>
        )
    }
}


export const ModalFinished = ({task}) => {

    const { setModalState } = modalStore(); 
    const [fadein, setFadein] = useState('fade-in');


    const {active_task, setActiveTask} = taskStore();
    const navigate = useNavigate();
    const sleep = ms => new Promise(r => setTimeout(r, ms));

    const runTask = async () => {
        let endpoint = `tasks/execute/${task.id}`;
        let result = await Api.getData(endpoint, [], 'get').then( async (response) => {
            toast.info('Taak wordt uitgevoerd');
            await sleep(5000);
            toast.success('Taak is afgerond');
            // refresh();

        }).catch(async (error)  => {
            if (error.response.status == 401) {
                let result = await Api.refreshToken().then((succes) => {
                    if (succes) {
                        // retry request
                        runTask();
                    } else {
                        // navigate to login
                        navigate('/login');
                    }
                }).catch((error) => {
                    // navigate to login
                    navigate('/login');
                });
            } else {
                console.log('different error? ', error);
            }
        });
    }

    const generatePDF = async () => {
        const endpoint = `/tasks/generate_pdf/${task.id}`;
        // prepeare download
        let result = await Api.getData(endpoint, [], 'get').then( async (response) => {

        }).catch(async (error)  => {
            if (error.response.status == 401) {
                let result = await Api.refreshToken().then((succes) => {
                    if (succes) {
                        // retry request
                        runTask();
                    } else {
                        // navigate to login
                        navigate('/login');
                    }
                }).catch((error) => {
                    // navigate to login
                    navigate('/login');
                });
            } else {
                console.log('different error? ', error);
            }
        });
    }

    const closeModal = () => {
        runTask();       
        setModalState({showModal: false});
    }
    

    if (task.status === 'open') {
        return (
            <div className={fadein +" modal-content"}>
                <div className="step-wrapper">
                    <SectionTitle>Gelukt! </SectionTitle>
                    <Description> De taak is aangemaakt en zal zometeen snel mogelijk worden uitgevoerd! </Description>
                    <ButtonsSection>
                        <ToolbarButton className="align-right" onClick={(event) => closeModal()}>Sluiten</ToolbarButton>
                    </ButtonsSection>
                </div> 
            </div>
        )
    } else if (task.status === 'finished') {
        return (
            <div className={fadein +" modal-content"}>
                <div className="step-wrapper">
                    <SectionTitle>{task.name} is afgerond.</SectionTitle>
                    <Description>De taak is afgerond. Je kan het resultaat bekijken of een PDF genereren.</Description>
                    <ButtonsSection>
                        <ToolbarButton className="align-right" onClick={(event) => generatePDF() }>PDF genereren</ToolbarButton>
                        <ToolbarButton className="align-right" onClick={(event) =>setModalState({showModal: false}) }>Sluiten</ToolbarButton>
                    </ButtonsSection>
                </div>
            </div>
        )
    } else {
        return (
            <div className={fadein +" modal-content"}>
                <div className="step-wrapper">
                    Onbekende status
                    <ButtonsSection>
                        <ToolbarButton className="align-right" onClick={(event) => closeModal()}>Sluiten</ToolbarButton>
                    </ButtonsSection>
                </div>
            </div>
        )
    }

}

