import React from 'react';
import Api from "../helpers/api";
import styled from 'styled-components';
import info_icon from '../assets/info_icon-min.svg';
import sources_icon from '../assets/sources_icon-min.svg';
import favo_icon from '../assets/favo_icon-min.svg';

// import { renderToStaticMarkup } from 'react-dom/server';

import {
    SectionTitle, 
    Cardtitle,
    ToolbarButton,
} from '../ui_components/general';

const ModalWrapper = styled.div`
    position: absolute;
    left: 0;
    transform: translateX(-100%);
    width: 22.47vw;
    min-height: 100px;
    z-index: 9;
    background-color: #ffffff;
    border-radius: 1em;
    padding: 2vw;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.3));
`;
const ActionWrapper = styled.div`
    border-radius: 3em;
    height: 2.1vw;
    padding: 10px;
    background-color: #ffffff;
    position: absolute;
    top: 25px;
    right: 90px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
`;
const AnswerActionButton = styled.button`
    background-color: transparent;
    border: none;
    width: 1.1vw;
    height: 1.1vw;
    padding: 0;
    margin: 0 8px;
`;

const AnswerTooltip = styled.div`
    position: absolute;
    right: 60px;
    bottom: 0;
    padding: 25px;
    background-color: #ffffff;
    border: 1px solid var(--shadow-color);
    border-radius: 8px;
`;

const SourcesModal = ({answer, showSources}) => {
    const wrapper_id = "modalwrapper-" + answer.id;
    
    const openDoc = async (file_id) =>  {
        // request download url
        // open new tab with said url
        let endpoint = `files/get-download-url/${file_id}`;
        if (file_id) {
            let download_url = await Api.getData( endpoint, [], 'get').then((response) => {
                if (response.data.success) {
                    window.open(Api.base_url + response.data.url, '_blank');
                }
            }).catch( async (err) =>  {
                console.log(err);
            });
        }

    }

    if (showSources) {
        return (
            <AnswerTooltip id={wrapper_id}>
                <Cardtitle>Bronnen</Cardtitle>
                {answer.sources && answer.sources.map((source) => (
                    <ToolbarButton onClick={() => { openDoc(source.file_id)}}>{source.filename}</ToolbarButton>
                ))}
            </AnswerTooltip>
        )
    }
}



const AnswerActions = ({answer}) => {
    const handleAnswerAction = async (event) => {
        console.log(event);
    }
    const toggleSourceModal = () => {
        if (showSources) {
            setShowSources(false);
        } else {
            setShowSources(true);
        }
    }

    const [showSources, setShowSources] = React.useState(false);

    return (
        <ActionWrapper>
        { /* <AnswerActionButton name="info" onClick={(event) => {handleAnswerAction(event)}} ><img style={{width: "100%", height:"100%"}} src={info_icon}/></AnswerActionButton> */ }
        { /*   <AnswerActionButton name="sources" data-tooltip-offset={50} data-tooltip-id="answer-tooltip" data-tooltip-place="left" data-tooltip-html={renderToStaticMarkup(<SourcesModal answer={answer}/>)} ><img style={{width: "100%", height: "100%"}} src={sources_icon}/></AnswerActionButton> */ }
        { /*<AnswerActionButton name="favorite" onClick={(event) => {handleAnswerAction(event)}} ><img style={{width: "100%", height: "100%"}} src={favo_icon}/></AnswerActionButton> */}

            <AnswerActionButton 
                name="sources" 
                onClick= {(event) => toggleSourceModal()}
            >
                <img style={{width: "100%", height: "100%"}} src={sources_icon}/>
            </AnswerActionButton>
            <SourcesModal answer={answer} showSources={showSources} />
        </ActionWrapper>
    );
};

export default AnswerActions;
