import React, {useEffect, useState} from 'react';

import {PageCenterWrapper, 
    FourColGrid,
    SectionTitle, 
    ListItem,
    ListFlexItem,
    ListItemCel, 
    ListItemLabel, 
    Input, 
    ListWrapper,
    Dropdown,
    DropdownList,
    DropdownListItem,
    DropdownToggle,
    ModalContent,
    Form, 
    AskButton,
    InlineButton,
    Textarea
} from '../../ui_components/general';

export const InputContainer = ({input, setInput, setDisabledButton}) => {
    const input_template = {
        'type' : 'text',
        'value': '',
        'description': 'beschrijving',
        'label': '',
        'required': false
    };
    const [inputs, setInputs] = React.useState((input ? input : []));
    
    const addInputToForm = (event) => {
        event.preventDefault();
        setInputs((inputs) => ([
            ...inputs, input_template
        ]));
        setInput(inputs);
    }
    const removeInputFromForm = (event) => {
        console.log('to be implemented');
    }

    const handleChange = (event, key) => {

        if (event.target.name == 'type') {
            let newInputs  = [...inputs];
            newInputs[key]['type'] = event.target.value;
            setInputs(newInputs);
        }
        if (event.target.name == 'label') {
            let newInputs  = [...inputs];
            newInputs[key]['label'] = event.target.value;
            setInputs(newInputs);
        }
        if (event.target.name == 'description') {
            let newInputs  = [...inputs];
            newInputs[key]['description'] = event.target.value;
            setInputs(newInputs);
        }
        if (event.target.name == 'value') {
            let newInputs  = [...inputs];
            newInputs[key]['value'] = event.target.value;
            setInputs(newInputs);
        }

        setInput(inputs);
        setDisabledButton(false);
    }
    
    const input_types = {
        "file"          : "Bestand",
        "text"          : "Tekst",
        "url"           : "Url",
        "textarea"      : "Lange tekst",
        "indexlocation" : "Kennisgebeid",
        "tone_of_voice" : "Toon"
    };


    return (
        <div className="inputs-wrapper">
            {inputs && inputs.map((i, index) => (
                <ListFlexItem key={index}>
                    <ListItemCel>
                        <ListItemLabel>Type</ListItemLabel>
                        <select 
                            value={i.type}
                            onChange={(event) => handleChange(event, index)} 
                            name="type" 
                            id={"type"+index}  
                        >
                          <option value="" >Kies een soort input</option>
                            {Object.keys(input_types).map(function(key) {
                                return <option data-selected={i.type} value={key}>{input_types[key]}</option>
                            })}
                        </select>
                    </ListItemCel>
                    <ListItemCel>
                        <ListItemLabel>Waarde</ListItemLabel>
                        <Input 
                            onChange= {(event) => handleChange(event, index)}
                            onFocusOut = {(event) => handleChange(event, index)}
                            name="value" 
                            value= {i.value}
                        />
                    </ListItemCel>
                    <ListItemCel>
                        <ListItemLabel>Label</ListItemLabel>
                        <Input 
                            onChange= {(event) => handleChange(event, index)}
                            onFocusOut = {(event) => handleChange(event, index)}
                            name="label" 
                            value= {i.label}
                        />
                    </ListItemCel>
                    <ListItemCel>
                        <ListItemLabel>Beschrijving</ListItemLabel>
                        <Input 
                            onChange= {(event) => handleChange(event, index)}
                            onFocusOut = {(event) => handleChange(event, index)}
                            name="description" 
                            value= {i.description}
                        />
                    </ListItemCel>
                </ListFlexItem >
            ))}
            <InlineButton onClick={(event) => {addInputToForm(event)}} >Toevoegen</InlineButton>
        </div>
    )
}
