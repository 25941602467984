import React, {useEffect, useState} from 'react';
import { toast } from 'react-toastify';
import Api from '../helpers/api';
import { FormatDate } from '../helpers/format';
import {ModalContent, ModalFinished} from './taskmodal';

import styled from 'styled-components';
import { useNavigate } from "react-router-dom";
import {taskStore, modalStore} from '../store';

import {PageCenterWrapper, 
    FourColGrid,
    SectionTitle, 
    ListItem,
    ListItemCel, 
    ListItemLabel, 
    Input, 
    ListWrapper,
    InlineButton,
} from '../ui_components/general';

const TaskListItem = ({task, refresh}) => {

    const {active_task, setActiveTask} = taskStore();
    const navigate = useNavigate();
    const sleep = ms => new Promise(r => setTimeout(r, ms));
    const { setModalState } = modalStore(); 

    const runTask = async () => {
        setActiveTask(task);
        let endpoint = `tasks/execute/${task.id}`;
        let result = await Api.getData(endpoint, [], 'get').then( async (response) => {
            toast.info('Taak wordt uitgevoerd');
            await sleep(5000);
            toast.success('Taak is afgerond');
            refresh();

        }).catch(async (error)  => {
            if (error.response.status == 401) {
                let result = await Api.refreshToken().then((succes) => {
                    if (succes) {
                        // retry request
                        runTask();
                    } else {
                        // navigate to login
                        navigate('/login');
                    }
                }).catch((error) => {
                    // navigate to login
                    navigate('/login');
                });
            } else {
                console.log('different error? ', error);
            }
        });
    }

    const showTask = async({task}) => {
        // get all steps 
        const get_steps_endpoint = `tasks/${task.id}/steps/`;

        let steps = await Api.getData(get_steps_endpoint, [], 'get').then( async (response) => {
            let steps = response.data;
            if (steps.length < 1) {
                return false;
            }
            showStep(task, steps, false);

        }).catch( async (error) => {
            if (error.response.status == 401) {
                let result = await Api.refreshToken().then((succes) => {
                    if (succes) {
                        // retry request
                        showTask();
                    } else {
                        // navigate to login
                        navigate('/login');
                    }
                }).catch((error) => {
                    // navigate to login
                    navigate('/login');
                });
            } else {
                console.log('different error? ', error);
            }

        });
        // show in modal in the same way it's done in the create stage.. so modal should be filled with the same function.
        // create task modal steps should be put into their own file and component
    }

    const showStep = async (task, steps, current_step) => {

        if(current_step === false) {
            current_step = 0;
        } else {
            current_step = current_step +1;
        }
        let step = steps[current_step];
        if (step) {
            const edit_steps_endpoint = `tasks/${task.id}/steps/${step.id}`;
            setModalState({
                title: task.name,
                modalContent: <ModalContent step={step} url={edit_steps_endpoint} task={task} steps={steps} next_step_function={showStep} current_step={current_step} action="edit" />,
                showModal: true,
                size: "large"
            });
        } else {
            setModalState({
                title: task.name,
                modalContent: <ModalFinished task={task} />,
                showModal: true,
                size: "small"
            });
        }
    }


    let created = 'aangemaakt op: ' + new FormatDate().formatted(task.created);
    let scheduled = 'wordt zo snel mogelijk uitgevoerd.';
    if (task.scheduled
        && task.scheduled.length > 0
    ) {
        if (new Date(task.scheduled).valueOf() > Date.now() ) {
            scheduled = 'gepland op '+new FormatDate().formatted(task.scheduled);
        }
    }
    let button_text = 'Uitvoeren';
    if (task.status == 'finished') {
        button_text = 'Bekijken';
    }
    if (task.status == 'finished') {
        return (     
            <li onClick={(event) => {showTask({task})}} className={"task-list-item " + task.status}>
            {task.id} - {task.name} 
            <span className="detail">{created}</span>
            <span className="detail">{scheduled}</span>
            <InlineButton className="align-right" onClick={(event) => {showTask({task})}}  >{button_text}</InlineButton>
            </li>
        )
    } else {
        return (
            <li onClick={(event) => {showTask({task})}} className={"task-list-item " + task.status}>
            {task.id} - {task.name} 
            <span className="detail">{created}</span>
            <span className="detail">{scheduled}</span>
            <InlineButton className="align-right" onClick={(event) => {runTask()}}  >{button_text}</InlineButton>
            </li>
        )
    }
}


export const TaskList = ({className, workflow_id, status}) => {
    // get workflows from API
    const post_data = {
        status: status,
        workflow_id: workflow_id
    }


    const [tasks, setTasks] = useState([]);
    const endpoint = 'tasks/select/'
    const getTasks = async () => { 
        await Api.getData(endpoint, post_data, 'post').then( (response) => {
            setTasks(response.data);
        }).catch( async (error) => {
            console.log(error);
        });
    }
    useEffect( () => {
        getTasks();
    }, [status]);

    return (
        <ul className={"task-list " + className}>
        {tasks &&
            tasks.map((task, index) => (
                <TaskListItem key={index} task={task} refresh={getTasks} />
            ))}
        </ul>
    )
}
