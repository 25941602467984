import React from 'react';
import styled from 'styled-components';
import answer_icon from '../assets/answer_icon-min.svg';
import question_icon from '../assets/question_icon-min.svg';

import AnswerActions from './answeractions';
import {useUserStore} from '../store';

const AnswerWrapper = styled.div`
    width: 100%;
    background-color: #EFEFEF;
    margin-left: 1.74vw;
    border-radius: 1em 0 0 1em;
    padding: 2vw 12.37vw;
    margin-top: 1vw;
    position: relative;
    z-index: 1;
`;
const QuestionWrapper = styled.div`
    width: 100%;
    background-color: #FFFFFF;
    margin-left: 1.74vw;
    padding: 2vw 12.37vw;
    margin-top: 1vw;
    position: relative;
    z-index: 1;
`;
const AnswerIcon = styled.div`
    position: absolute;
    left: 11.37vw;
    top: 2.1vw;
    width: 0.89vw;
    height: 0.89vw;
    transform: translate(-100%);
`;

const Answer = ({answer}) => {
    const initials = useUserStore((state) => state.initials);
    return (
        <>
        <QuestionWrapper>
            <AnswerIcon>        
                <img src={question_icon} alt="Question Icon" /> 
            </AnswerIcon>
            <div className="questioun_content_wrapper">
                <span className="question_initials">{initials}...</span>
                <span className="question_content">{answer.question}</span>
            </div>
        </QuestionWrapper>
        <AnswerWrapper>
            <AnswerIcon>        
                <img src={answer_icon} alt="Answer Icon" /> 
            </AnswerIcon>
            <div className="answer_content_wrapper">
                <span className="answer_initials">YO...</span>
                <span className="answer_content">{answer.answer}</span>
            </div>
        <AnswerActions answer={answer} />
        </AnswerWrapper>
        </>
    )
};

export default Answer;
