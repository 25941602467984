import React from 'react';
import Api from '../helpers/api';

import {useFilesStore} from '../store';

import styled from 'styled-components';
// imports for filesform
import {useDropzone} from 'react-dropzone'
import {useCallback} from 'react'

import {PageCenterWrapper, 
    SectionTitle, 
    ContainerTitle, 
} from '../ui_components/general';


const Filerow = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    min-width: 200px;
`;
const FileCel = styled.div`
    color: var(--you-green);
    border: 1px solid var(--you-green);
    border-radius: 3px;
    width: 100%;
    padding: 8px 12px;
    position: relative;
`;
const FileAction = styled.button`
    color: #ffffff;
    font-size: 16px;
    background-color: transparent;
    box-shadow: none;
    border: 1px solid #ffffff;
    margin: 3px;
    position: absolute;
    right: 0.5em;
    width: 1em;
    cursor: pointer;
    &:hover {
        background-color: var(--you-green);
    }
    &:first-child {
        right: 2em;
    }
`;
const downloadFile = async (file_id, file_name) => {
    let endpoint = `files/${file_id}/download`;
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);
    let file_data = await Api.readFile(endpoint).then( (data) => {
        let objectUrl = window.URL.createObjectURL(new Blob([data.data]));
        anchor.href = objectUrl;
        anchor.download = file_name;
        anchor.click();
        window.URL.revokeObjectURL(objectUrl);
    });
}

export const File = ({file}) => {
    return (
        <Filerow>
            <FileCel>{file.name}
                { /* 
                    download doesn't work properly.
                    needs to be changed to get a signed download url from Back-end
                <FileAction>V</FileAction>
                <FileAction onClick={(event) => {downloadFile(file.id, file.name)}}>D</FileAction>
                */ }
            </FileCel>
        </Filerow>
    )
}

export const UploadFile = ({file}) => {
    return (
        <li>
            <Filerow> 
                {file.name}
            </Filerow>
        </li>
    )
}

const getColor = (props) => {
  if (props.isDragAccept) {
      return '#00e676';
  }
  if (props.isDragReject) {
      return '#ff1744';
  }
  if (props.isFocused) {
      return '#2196f3';
  }
  return '#eeeeee';
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

export const FileForm = () => {
    const endpoint = 'files/';
    const {addFile, files_to_upload, removeFromFilesToUpload } = useFilesStore();
    const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
        onDrop: (files) => {
           add_to_que(files); 
        }
  });
    const startUpload = async () => {
        if (files_to_upload.length < 1 ) {
            return ;
        }
        files_to_upload.map(async (file) => {
            const safename = file.safename;
            // build formdata object 
            let formData = new FormData();
            formData.append('file', file);
            await Api.getData(endpoint, formData, 'file').then( (response) => {
                console.log(response);
                // add to filestore id
                removeFromFilesToUpload(safename);
                addFile(response.data);
            });

        });


    }

    const add_to_que = (files) => {
        files.forEach(function(file) {
            let filename = file.name.replace(/[^a-z0-9]/gi, '_').toLowerCase();
            file['safename'] = filename;
            files_to_upload.push(file);
        });

        let result = startUpload();
    }
  
  return (
    <div className="container">
      <Container {...getRootProps({isFocused, isDragAccept, isDragReject})}>
        <input {...getInputProps()} />
        <p>Laat bestanden hier vallen om ze in de index op te nemen.</p>
      </Container>
      <aside>
        {(files_to_upload.length > 1) ? 
        <ContainerTitle>Geselecteerde bestanden: </ContainerTitle>
            : ''}
        <ul>{files_to_upload && files_to_upload.map((file) => (
            <UploadFile key={file.safename} file={file} >{file.name} - {file.size}</UploadFile>   
        ))}</ul>
      </aside>
    </div>
  );
}
