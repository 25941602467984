import React, {useEffect} from 'react';

import styled from 'styled-components';
import {modalStore} from '../store';

import backdrop from '../assets/backdrop-min.svg';

import {
    PageCenterWrapper, 
    FourColGrid,
    SectionTitle, 
    ListItemCel, 
    ListItem, 
    ListItemLabel, 
    Form, 
    AskButton,
    ListWrapper
} from '../ui_components/general';

const ModalWrapper = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    background-color: rgb(46 58 55 / 70%);
    backdrop-filter: blur(6px);
    z-index: 9999999;
    &.hidden {
        display: none !important;
        position: relative;
    }
`;
const ModalWindow = styled.div`
    width: 600px;
    max-height: 80vh;
    position: absolute;
    top: 10vh;
    overflow-y: auto;
    background-color: var(--light-bg);
    border-radius: 8px;
    filter: drop-shadow( 0 0 24px  rgb(7, 18, 16));
    &.large {
        width: 80vw;
        min-width: 600px;
    }&:before {
        content: '';
        width: 100%;
        height: 100%;
        pointer-events: none;
        opacity: 0.05;
        background-image: url(${backdrop});
        -webkit-filter: blur(45px);
        -moz-filter: blur(45px);
        -o-filter: blur(45px);
        -ms-filter: blur(45px);
        filter: blur(45px);
        right: 0;
        bottom: 0;
        position: absolute;
        background-size: cover;
        background-repeat: no-repeat;

    }
`;
const ModalToolbar = styled.div`
    display: flex;
    justify-content: flex-end;
    color: var(--you-purple);
    padding: 20px;
    & span {
        cursor: pointer;
        transition: all 0.3s ease-out;    
    }
    & span:hover {
            font-weight: bold;
    }
`;

const ModalTitle = styled.div`
    font-weight: 400;
    color: var(--you-purple-light);
    font-size: 1.3em;
    width: 100%;
    text-align: center;
`;
const ModalContent = styled.div`
    padding: 25px;
`;
export const Modal = () => {

    const { showModal, size, title, modalContent, setModalState } = modalStore(); 

    let modalstateClassName= 'hidden';
    let content = null;
    if (showModal) {
        modalstateClassName = "visible";
    }
    useEffect( () => {
        // console.log(title);
        // console.log(modalContent);
    }, [showModal, title, modalContent]);
    
    return (

        <ModalWrapper className={modalstateClassName + " modal-wrapper"} >
            <ModalWindow className={"modal "+size} >
                <ModalToolbar className="modal-toolbar"><ModalTitle>{title}</ModalTitle><span onClick={(event) => setModalState({showModal:false})} >X</span></ModalToolbar>
                <ModalContent>
                    {modalContent}
                </ModalContent>
            </ModalWindow>
        </ModalWrapper>
    )
}
