import React from 'react';
import styled from 'styled-components';
import Api from '../helpers/api';
import nextStep from "../helpers/nextStep";
import { useNavigate } from "react-router-dom";

import sendIcon from '../assets/send-icon-min.svg';
import arrowDown from '../assets/arrow-down-min.svg';
import {modalStore} from '../store';
import {workflowStore} from '../store';

import {PageCenterWrapper, 
    FourColGrid,
    SectionTitle, 
    ListItemCel, 
    InputWrapper,
    ListItem, 
    ListItemLabel, 
    Form, 
    Input, 
    AskButton,
    ListWrapper
} from '../ui_components/general';

const Step = ({step, nextStep}) => {
    return (
        <div className="step-wrapper">
            <SectionTitle>{step.name}</SectionTitle>
            {step.instruction}
            <ListWrapper>
                {step.input 
                    && step.input.map((item, index) => (
                    <ListItem key={index}>
                        <InputWrapper className="inputWrapper">
                            <ListItemLabel>{item.type}</ListItemLabel>
                            <Input
                                name={item.type}
                                value={item.value}
                            />
                        </InputWrapper>
                    </ListItem>
                ))}
            </ListWrapper>
            <span onClick={(event) => nextStep()}>Volgende Stap</span>
        </div> 

    )
}
const ModalContent = ({steps}) => {
    const nextStep = () => {
        console.log('hiero');
    }
    return (
        <div className="modal-content">
            {steps && 
                steps.map((step) => (
                    <Step step={step} nextStep={nextStep} />
                ))}
        </div>
    )
}

export const Workflow = ({workflow}) => {
    
    const { setModalState } = modalStore(); 
    const {activeWorkflow, workflows, setActiveWorkflow } = workflowStore(); 

    const navigate = useNavigate();
    const gotoWorkflow = () => {
        setActiveWorkflow(workflow.id);
        navigate(`/workflow/${workflow.id}`)
    }

    const startWorkflow = async () => {
        // get steps 
        const endpoint = `workflows/${workflow.id}/steps/`;
        let result = await Api.getData(endpoint, [], 'get').then((response) => {
            let steps = response.data;
            if (steps.length < 1) {
                return false;
            }

            setModalState({
                title: workflow.name,
                modalContent: <ModalContent steps={steps} />,
                showModal: true
            });
        }).catch( async (error) => {
            
        });

        // prompt for information
        // send to API to create tasks

    }

    
    return (
        <li onClick={(event) => gotoWorkflow()} className="task-list-item">
        {workflow.id} - {workflow.name} 
        </li>
    )
}
