import {create} from 'zustand';
import {produce} from "immer";
import logo from './assets/alt-logo-min.svg';

const useStore = create(set => ({
  input: '',
  setInput: (value) => set({ input: value }),
}));

const useCredentialStore = create(set => ({
    email: '',
    password: '',
    setEmail: (emailVal) => set({email: emailVal}),
    setPass: (passVal) => set({password: passVal})
}));


const useUserStore = create(set => ({
    id: '',
    firstname: '',
    lastname: '',
    initials: '',
    email: '',
    last_login: '',
    setFirstname: (firstname) => set({
        firstname: firstname
    }),
    setLastname: (lastname) => set({
        lastname: lastname
    }),
    setUser: (user_data) => set({
        id: user_data.id,
        firstname: user_data.firstname,
        lastname: user_data.lastname,
        initials: user_data.initials,
        email: user_data.email
    })
}));

const useCompanyUserStore = create(set => ({

}));

const useAnswerStore = create(set => ({
    answers: [], 
    addAnswer: (answer) => {
        set((state) => ({
            answers: [
                ...state.answers,
                answer
            ],
        }));
    },
}));
/*
const useLocationStore = create((set, get) => ({
    indexLocations: [], 
    activeLocation: null,
    activeLocationName: null,
    addLocation: (indexlocation) => {
        set((state) => ({
            indexLocations: [
                ...state.indexLocations,
                indexlocation
            ],
        }));
    },
    setActiveLocation : (activeLocation) => {
        let locations = get().indexLocations;
        locations.forEach(function(index_location) {
            if (index_location.id == activeLocation) {
                set((state) => ({
                    activeLocationName: index_location.name,
                    activeLocation: activeLocation
                }));
            }
        });
    },
    clearLocations: () => {
        set((state) => ({
            indexLocations: []
        }))
    }
}));
*/

const useLocationStore = create((set, get) => ({
    indexLocations: [], 
    activeLocation: null,
    activeLocationName: null,
    addLocation: (indexlocation) => {
        let new_locations = get().indexLocations;
        new_locations[indexlocation.id] = indexlocation;
        set((state) => (
            {
                indexLocations: new_locations
            }
        ))
    },
    setActiveLocation : (activeLocation) => {
        let selected_location = get().indexLocations[activeLocation];
        set((state) => ({
            activeLcoationName: selected_location.name,
            activeLocation: activeLocation
        }))
    },
    clearLocations: () => {
        set((state) => ({
            indexLocations: [],
            activeLocation: null, 
            activeLocationName: null
        }))
    }
}));

const useQuestionStore = create((set, get) => ({
    questions: {},
    addQuestion: (question) => {
        let new_questions = get().questions;
        let new_list_key = window.crypto.randomUUID(); 
        question["list_key"] = new_list_key;
        question.answer = "";
        new_questions[new_list_key] = question
        set((state) => (
            {
                questions: new_questions
            }
        ));
        return new_list_key;
    },
    updateQuestion: (question, list_key) => {
        question["list_key"] = list_key;
        let new_questions = get().questions;
        new_questions[list_key] = question;
        set((state) => ({
            questions: new_questions
        }));
        console.log(get().questions);
    }
}));

const useCompanyStore = create((set, get) => ({
    companies : {},
    activeCompany : null,
    setActiveCompany : (activeCompany) => {
        let companies = get().companies;
        if(companies[activeCompany]) {
            set((state) => ({
                activeCompany: activeCompany,
                getCompanyName: companies[activeCompany].name
            }))
        }
    }, 
    addCompany: (company) => {
        let new_companies = get().companies;
        new_companies[company.id] = company;
        set((state) => (
            {
                companies: new_companies
            }
        ));
    },
    getCompanyName: null
}));

const useFilesStore = create((set, get) => ({
    files: [],
    activeLocation: null,
    files_to_upload: [],
    loading_files: false,
    reload: false,
    setReload: (value) => {
        set((state) => ({
            reload: value
        }));
    },
    setLoading: (value) => {
        set((state) => ({
            loading_files: !state.loading_files
        }));
    },
    clearFiles: () => {
        set( (state) => ({
            files: [],
            reload: true
        }));
    },
    removeFromFilesToUpload: (safename) => {

        set((state) => ({
            files_to_upload : [
                state.files_to_upload.filter((file) => file.safename !== safename)
            ]
        }))
    },
    setActiveLocation: (activeLocation) => {
        set((state) => (
            {
                activeLocation: activeLocation
            }
        ));
    },
    addFile: (file) => {
        set( (state) => ({
            files: [
                ...state.files, 
                file
            ]
        }));
    }
}));

const useInterfaceStore = create((set, get) => ({
    // loggedIn: false,
    showDrawer: false,
    showIcon: false,
    showOptions: false,
    loading: true,
    reload: false,
    title: 'Younifai',
    logoSrc: logo,
    lastKnownScreen: null,
    setLastKnownScreen: (screenname) => {
        set((state) => ({
            lastKnownScreen: screenname
        }))
    },
    setLogoSrc: (logo_src) => {
        set((state) => ({
            logoSrc: logo_src
        }))
    },

    setLoading: () => {
        set((state) => ({
            loading: !state.loading
        }))
    },
    setTitle: (value) => {
        set((state) => ({
            title: value
        }))
    },
    setLoggedIn: (value) => {
        set((state) => ({
            loggedIn: value
        }))
    },
    setReload: (value) => {
        set((state) => ({
            reload: value
        }))
    },
    openDrawer: () => {
        set((state) => ({
            showDrawer : true
        }
        ));
    },
    closeDrawer: () => {
        set((state) => ({
            showDrawer : false
        }
        ));
    }
}));

const promptStore = create((set, get) => ({
    prompts: [],
    addPrompts: (prompts) => {
        set((state) => ({
            prompts: prompts
        }))
    },
    updatePrompt: (prompt) => {
        // to implements using key vcalue store
        console.log('nog te implementeren');
    }

}));

const workflowStore = create((set, get) => ({
    workflows: [],
    activeWorkflow: {},
    addWorkflows: (workflows) => {
        set((state) => ({
            workflows: workflows
        }))
    },
    clearWorkflows: () => {
        set((state) => ({
            workflows: []
        }))
    },
    addWorkflow: (workflow) => {
        set((state) => ({
            workflows: [...state.workflows, workflow] 
        }))
    },
    updateWorkflow: (workflow) => {
        console.log('nog te implementeren');
    },
    setActiveWorkflow: (workflow_id) => {
        let workflows = get().workflows;
        workflows.forEach(function(workflow) {
            if (workflow.id == workflow_id) {
                set((state) => ({
                    activeWorkflow: workflow
                }))
            }
        });
    }
}));

const taskStore = create((set, get) => ({
    active_task: null,
    setActiveTask: (task) => {
        set((state) => ({
            active_task : task
        }))
    } 
}));

const modalStore = create((set, get) => ({
    modalContent: '',
    size: "small",
    showModal: false,
    title: '',
    setModalState: (modalState) => {
        set((state) => ({
            size: (modalState.size ? modalState.size : "small"),
            showModal: modalState.showModal,
            modalContent: modalState.modalContent,
            title: modalState.title
        }) ); 
    },


}));

export {useStore, useAnswerStore, useCredentialStore, useUserStore, useLocationStore, useCompanyStore, useFilesStore, useInterfaceStore, promptStore, workflowStore, modalStore, taskStore, useQuestionStore};
