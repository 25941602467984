import React, {useEffect} from 'react';
import Api from "../helpers/api";
import {sleep} from "../helpers/helpers";
import { useNavigate } from "react-router-dom";

import {Company, CompanyForm} from '../components/company';

import {PageCenterWrapper, 
    FourColGrid,
    SectionTitle, 
    ListItemCel, 
    ListItemLabel, 
    Form, 
    Input, 
    AskButton,
    ListWrapper,
    Toolbar,
    ToolbarButton,
} from '../ui_components/general';

import {useCompanyStore, useInterfaceStore, modalStore} from '../store';

const Companies = () => {
    const {companies, addCompany } = useCompanyStore();
    // const {loggedIn} = useInterfaceStore();
    const navigate = useNavigate();
    const {setTitle} = useInterfaceStore();
    const { setModalState } = modalStore(); 

    const getCompanies = async () => {
        if (!companies 
            || Object.keys(companies).length < 1
        ) {
            let endpoint = 'companies/';
            let data = {};
            let response = await Api.getData(endpoint, data, 'get').then( (response) => {
                let data_companies = response.data;
                data_companies.forEach(function (company) {
                    addCompany(company);
                });

                console.log(companies);
            }).catch(async (error) => {
                if (error.response.status == 401) {
                    if (Api.state.busy) {
                        await sleep(400);
                    }
                    let result = await Api.refreshToken().then((succes) => {
                        if (succes) {
                            // retry request
                            getCompanies();
                        } else {
                            // navigate to login
                            navigate('/login');
                        }
                    }).catch((error) => {
                        // navigate to login
                        navigate('/login');
                    });
                }
            });
        }
    }
    const showCompanyForm = async () => {
        setModalState({
            title: "Werkgebied toevoegen",
            modalContent: <CompanyForm />,
            showModal: true,
            size: "large"
        });
    }

    useEffect(() => {
        getCompanies();
        setTitle('Overzicht werkgebieden');
    }, []);
    return (
        <>
        <Toolbar>
            <ToolbarButton onClick={(event) => showCompanyForm()}>werkgebied toevoegen</ToolbarButton>
            <ToolbarButton onClick={(event) => getCompanies()}>werkgebieden ophalen</ToolbarButton>
        </Toolbar>
        <PageCenterWrapper>
        {/*<CompanyForm /> company form could be in modal adding a company is most likely not an everyday action*/}
            <FourColGrid>
                {companies && Object.entries(companies).map(([id, company]) => (
                    <Company key={id} props={company} />
                ))}
            </FourColGrid>
        </PageCenterWrapper>
        </>
    );
}

export default Companies;
