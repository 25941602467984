import React from 'react';
import { useRef, useEffect } from 'react';
// import { Tooltip } from 'react-tooltip'
import styled from 'styled-components';

import {useQuestionStore} from '../store';
import Answer from './answer';

const Questionlist = styled.div`
    position: relative;
    display: flex;
    align-content: flex-end;
    flex-wrap: wrap;
`;
const AlwaysScrollToBottom = () => {
  const elementRef = useRef();
  useEffect(() => elementRef.current.scrollIntoView());
  return <div ref={elementRef} />;
};

const Question = ({question}) => {
    return (
        <>
        <div>
            {question.question}
        </div>
        <div>
            {question.answer}
        </div>
        </>  
    )
}



const QuestionList = () => {
    const {questions, addQuestion} = useQuestionStore();
    return (
        <>
        <Questionlist>
            {questions && 
                Object.keys(questions).map(key => 
                    <Answer key={questions[key].list_key} answer={questions[key]} />
                )
            }
            <AlwaysScrollToBottom />
        </Questionlist>
        { /*<Tooltip clickable openOnClick id="answer-tooltip" className="sources_tooltip" />*/ }
        </>
    );
};

export default QuestionList;
