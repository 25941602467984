import React, {useEffect, useState} from 'react';
import Api from "../helpers/api";
import { toast } from 'react-toastify';

import AnswerList from '../components/answerlist';
import QuestionList from '../components/questionlist';
import {LocationList} from '../components/locationlist';
import { useNavigate, useParams } from "react-router-dom";
import {useCompanyStore, useInterfaceStore, modalStore, useLocationStore, useAnswerStore, useQuestionStore} from '../store';

import {PageCenterWrapper, 
    FourColGrid,
    SectionTitle, 
    ListItemCel, 
    ListItemLabel, 
    Form,
    ChatForm,
    Input, 
    AskButton,
    InlineButton,
    ListWrapper,
    Cardgroup, 
    SmallCard, 
    Cardtitle,
    AreaWrapper,
    SidebarLayout,
    Sidebar,
    Workspace,
    Toolbar,
    ToolbarButton,
    ButtonsSection
} from '../ui_components/general';

export const Chat = () => {

    const {setTitle, setLastKnownScreen, reload, setReload} = useInterfaceStore();
    const navigate = useNavigate();
    const [submitted, setSubmitted] = useState(false);
    const [answerScroll, setAnswerScroll] = useState(0);
    const [ input, setInput ] = useState('');
    const {answers, addAnswer} = useAnswerStore();
    const {questions, addQuestion, updateQuestion} = useQuestionStore();
    const {activeLocation} = useLocationStore();

    const handleChange = async (event) => {
        event.preventDefault();
        setSubmitted(true);
        let endpoint = 'questions/ask';
        let question = event.target.question.value;
        const question_data = {'question': question};
        // add Question to list
        let list_key = addQuestion(question_data);
        try {
            let answer = await Api.getData(endpoint, question_data).then( (answer) => {
                // addAnswer(answer.data);
                updateQuestion(answer.data, list_key);
            });
        } catch (err) {
            if (err.response == undefined) {
                // show unknown error
                console.log(err);
            } else if (err.response.status == 401) {
                Api.logOut();
                navigate('/login');
            } else {
                // show specific status code errors
            }
        }
        event.target.question.value = '';
        setSubmitted(false);
    }
    // is there an activeLocation, if not set message and choose 
    // indexes as start screen


    const [workspaceType, setWorkspaceType] = useState('chat');

    const renderWorkspace = ({workspaceType}) => {

        // determine what wqorkspace to render
        if (workspaceType == 'chat') {
            return (
                <div style={{"height":"100%", "overflow-y": "auto"}} >
                    <QuestionList />
                    <div className="space-saver"></div>
                </div>
            )
        } else if (workspaceType == 'indexes') {
            return <LocationList />
        }
    }

    const clearScreen = () => {
        setWorkspaceType('chat');
    }

    useEffect(() => {
        if (!activeLocation) {
            setLastKnownScreen('/chat');
            toast.warning('Kies eerst een Kennisgebied.');
            setWorkspaceType('indexes');
        }
        if (reload) {
            setReload(false);
            setWorkspaceType('chat');
        }
        
    }, [activeLocation, reload])


    return (
        <>
        <Toolbar>
            <ToolbarButton onClick={(event) => navigate('/companies')}>Werkgebieden overzicht</ToolbarButton>
            <ToolbarButton onClick={(event) => {clearScreen()}}>Scherm wissen</ToolbarButton>
        </Toolbar>

        <SidebarLayout>
            <Sidebar>
                <SmallCard className={(workspaceType == 'indexes' ? 'active' : '')} onClick={(event) => {setWorkspaceType('indexes')}} >
                    <Cardtitle>Kennisgebieden</Cardtitle>
                </SmallCard>
                <SmallCard>
                    <Cardtitle>Vraag geschiedenis</Cardtitle>
                </SmallCard>
                <SmallCard>
                    <Cardtitle>Iets anders</Cardtitle>
                </SmallCard>
            </Sidebar>
            <Workspace  style={{"height":"55vh"}} >
                {renderWorkspace({workspaceType})}
                {/* render chat and questionform */}

                <ChatForm className={(submitted ? "submitted" : '')} onSubmit={(event) => {handleChange(event)}}>
                    <Input
                        name = "question"
                        placeholder = "Stel een vraag ..."
                        style = {{"margin-right": "20px"}}
                    />
                    <InlineButton type="submit">Verstuur</InlineButton>
                </ChatForm>
            </Workspace>
        </SidebarLayout>
        </>
    )
}
