import React from 'react';
import {useState, useEffect} from 'react';
import {Buffer} from 'buffer';
import younifai_logo from '../assets/younifai-min.svg';
import {useDropzone} from 'react-dropzone'
import nextStep from "../helpers/nextStep";
import { useNavigate } from "react-router-dom";
import Api from "../helpers/api";
import sendIcon from '../assets/send-icon-min.svg';
import {UsersListModal} from './usersmodal';

import styled from 'styled-components';
import {modalStore, workflowStore, useCompanyStore, useInterfaceStore, useLocationStore} from '../store';
import {PageWrapper, 
    SectionTitle, 
    ListWrapper,
    ListItem,
    ListItemCel, 
    ListItemLabel, 
    Form, 
    Input, 
    AskButton,
    Cardgroup,
    SmallCard, 
    Cardtitle,
    ToolbarButton,
    InlineButton,
    ButtonsSection,
} from '../ui_components/general';

const CompanyItem = styled.li`
    width: 12vw;
    aspect-ratio: 1/1.3;
    margin: 30px;
    background-color: rgba(255, 255, 255, 0.15);
    padding: 22px;
    border-radius: 35px 0;
    cursor: pointer;
    filter: drop-shadow(0px 0px 0px transparent);
    transition: all 0.3s ease-out;
    box-shadow: 0 0 8px var(--shadow-color);
    &:hover {
        background-color: rgba(255, 255, 255, 0.5);
    }
    &.active {
        box-shadow: 0 0 24px var(--shadow-color);
        background-color: rgba(255, 255, 255, 1);
    }
`;
const ImageWrapper = styled.div`
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 22px 0 0 0;
    background-repeat: no-repeat;
    background-position: center;
    & img {
        max-width: 80%;
        max-height: 80%;
        object-fit: contain;
    }
`;
const InfoWrapper = styled.div`
    color: var(--you-green);
    padding: 22px;
`;


const SendButton = styled.button`
    position: absolute;
    display: block;
    background-color: #2371CB;
    color: #ffffff;
    background-image: url(${sendIcon});
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 17px 18px;
    width: fit-content;
    height: fit-content;
    border-radius: 3em;
    border: none;
    font-size: 14px;
    padding: 12px;
    left: 100%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
        filter: brightness(1.1);
    }
    &:disabled {
        filter: brightness(0.75);
    }
`;

export const CompanyConfigForm = ({company, config}) => {
    const [fadein, setFadein] = useState('fade-in');
    const { setModalState } = modalStore(); 
    const {companies, addCompany } = useCompanyStore();
    const endpoint = (config ? `companies/${company.id}/configs/${config.id}` : `companies/${company.id}/configs/`) ; 
    const [disabledButton, setDisabledButton] = React.useState(true);

    const defaultConfigName = (config ? config.config_name : '')
    const defaultModelName = (config ? config.model_name : '')
    const defaultModelLocation = (config ? config.model_location : '')
    const defaultApiKey = (config ? config.model_api_key : '')
    const defaultConfigDefault = (config ? config.default : false)
    
    const [configName, setConfigName] = React.useState(defaultConfigName);
    const [modelName, setModelName] = React.useState(defaultModelName);
    const [modelLocation, setModelLocation] = React.useState(defaultModelLocation);
    const [apiKey, setApiKey] = React.useState(defaultApiKey);
    const [configDefault, setConfigDefault] = React.useState(defaultConfigDefault);
    
    const {setTitle} = useInterfaceStore();

    const handleChange = (event) => {
        if (event.target.name == 'config_name') {
            setConfigName(event.target.value);
        }
        if (event.target.name == 'model_name') {
            setModelName(event.target.value);
        }
        if (event.target.name == 'model_location') {
            setModelLocation(event.target.value);
        }
        if (event.target.name == 'api_key') {
            setApiKey(event.target.value);
        }
        if (event.target.name == 'config_default') {
            console.log(event.target.checked ? true : false);

            setConfigDefault(event.target.checked ? true : false);
        }

        if (configName 
            && modelName
            && modelLocation
        ) {
            setDisabledButton(false);
        }

    } 

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (configName 
            && modelName
            && modelLocation
        ) {
            let data = {
                'config_name' : configName,
                'model_name' : modelName,
                'model_location' : modelLocation, 
                'model_api_key' : apiKey,
                'default' : configDefault
            };
            let response = await Api.getData(endpoint, data, 'post').then( (response) => {
                if (response.data.success) {
                    data['id'] = response.data.id;
                    // refresh form
                    // addCompany(data);
                    setConfigName('');
                    setModelName('');
                    setModelLocation('');
                    setApiKey('');
                    setConfigDefault(false);

                    setModalState({
                        showModal: false
                    });
                }
            }).catch(async(error) => {
                console.log(error);
            });
        }
    }
    const closeModal = async () => {
        setModalState({
            showModal: false
        });
    }
    const defaultChecked = (configDefault ? "checked=checked" : '');

    return (
        <div className={fadein +" modal-content"}>
        <Form id="company_form" onSubmit={(event) => { handleSubmit(event)}}>
            <ListItemCel>
                <ListItemLabel>Config naam</ListItemLabel>
                <Input 
                    onChange= {(event) => handleChange(event)}
                    onFocusOut = {(event) => handleChange(event)}
            
                    name="config_name" 
                    value= {configName}
                />
            </ListItemCel>
            <ListItemCel>
                <ListItemLabel>Model naam</ListItemLabel>
                <Input 
                    name="model_name" 
                    onChange= {(event) => handleChange(event)}
                    onFocusOut = {(event) => handleChange(event)}
                    value= {modelName}
                />
            </ListItemCel>
            <ListItemCel>
                <ListItemLabel>Model locatie</ListItemLabel>
                <Input 
                    name="model_location" 
                    onChange= {(event) => handleChange(event)}
                    onFocusOut = {(event) => handleChange(event)}
                    value= {modelLocation}
                />
            </ListItemCel>
            <ListItemCel>
                <ListItemLabel>Api Key</ListItemLabel>
                <Input 
                    name="api_key" 
                    onChange= {(event) => handleChange(event)}
                    onFocusOut = {(event) => handleChange(event)}
                    value= {apiKey}
                />
            </ListItemCel>
            <ListItemCel>
                <ListItemLabel>Configuratie actief</ListItemLabel>
                <Input 
                    type="checkbox"
                    name="config_default" 
                    onChange= {(event) => handleChange(event)}
                    onFocusOut = {(event) => handleChange(event)}
                    checked = {configDefault}
                />
            </ListItemCel>
        </Form>
        <ButtonsSection>
            <ToolbarButton  onClick={(event) => {closeModal()}} className="align-left">Annuleren</ToolbarButton>
            <ToolbarButton form="company_form" disabled={disabledButton} className="align-right">Opslaan</ToolbarButton>
        </ButtonsSection>
        </div>
    );

}

export const CompanyForm = ({company}) => {
    // add company form
//
    const [fadein, setFadein] = useState('fade-in');
    const { setModalState } = modalStore(); 
    const {companies, addCompany } = useCompanyStore();
    const endpoint = (company ? `companies/${company.id}` : 'companies/') ; 
    const [disabledButton, setDisabledButton] = React.useState(true);


    const defaultEmail = (company ? company.email : '')
    const defaultName = (company ? company.name : '')
    const defaultStreet = (company ? company.street : '')
    const defaultHouseNumber = (company ? company.house_number : '')
    const defaultZipCode = (company ? company.zip_code : '')

    const [name, setName] = React.useState(defaultName);
    const [street, setStreet] = React.useState(defaultStreet);
    const [house_number, setHouseNumber] = React.useState(defaultHouseNumber);
    const [zip_code, setZipCode] = React.useState(defaultZipCode);
    const [email, setEmail] = React.useState(defaultEmail);
    const {setTitle} = useInterfaceStore();

    const handleChange = (event) => {
        if (event.target.name == 'name') {
            setName(event.target.value);
        }
        if (event.target.name == 'street') {
            setStreet(event.target.value);
        }
        if (event.target.name == 'house_number') {
            setHouseNumber(event.target.value);
        }
        if (event.target.name == 'zip_code') {
            setZipCode(event.target.value);
        }
        if (event.target.name == 'email') {
            setEmail(event.target.value);
        }


        if (name && email) {
            setDisabledButton(false);
        }
    } 

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (name
        && email
        ){
            let data = {
                'name' : name,
                'street' : street,
                'house_number' : house_number,
                'zip_code' : zip_code,
                'email' : email
            };
            let response = await Api.getData(endpoint, data, 'post').then( (response) => {
                if (response.data.success) {
                    data['id'] = response.data.id;
                    // refresh form
                    addCompany(data);
                    setTitle(data.name);
                    setName('');
                    setStreet('');
                    setHouseNumber('');
                    setZipCode('');
                    setEmail('');

                    setModalState({
                        showModal: false
                    });
                }
            }).catch(async(error) => {
                console.log(error);
            });
        }
    }
    const closeModal = async () => {
        setModalState({
            showModal: false
        });
    }


    return (
        <div className={fadein +" modal-content"}>
        <Form id="company_form" onSubmit={(event) => { handleSubmit(event)}}>
            <ListItemCel>
                <ListItemLabel>Naam</ListItemLabel>
                <Input 
                    onChange= {(event) => handleChange(event)}
                    onFocusOut = {(event) => handleChange(event)}
            
                    name="name" 
                    value= {name}
                />
            </ListItemCel>
            <ListItemCel>
                <ListItemLabel>Straat</ListItemLabel>
                <Input 
                    name="street" 
                    onChange= {(event) => handleChange(event)}
                    onFocusOut = {(event) => handleChange(event)}
                    value= {street}
                />
            </ListItemCel>
            <ListItemCel>
                <ListItemLabel>Huisnr</ListItemLabel>
                <Input 
                    name="house_number" 
                    onChange= {(event) => handleChange(event)}
                    onFocusOut = {(event) => handleChange(event)}
                    value= {house_number}
                />
            </ListItemCel>
            <ListItemCel>
                <ListItemLabel>Postcode</ListItemLabel>
                <Input 
                    name="zip_code" 
                    onChange= {(event) => handleChange(event)}
                    onFocusOut = {(event) => handleChange(event)}
                    value= {zip_code}
                />
            </ListItemCel>
            <ListItemCel>
                <ListItemLabel>Email</ListItemLabel>
                <Input 
                    name="email" 
                    onChange= {(event) => handleChange(event)}
                    onFocusOut = {(event) => handleChange(event)}
                    value= {email}
                />
            </ListItemCel>
        </Form>
        <ButtonsSection>
            <ToolbarButton  onClick={(event) => {closeModal()}} className="align-left">Annuleren</ToolbarButton>
            <ToolbarButton form="company_form" disabled={disabledButton} className="align-right">Opslaan</ToolbarButton>
        </ButtonsSection>
        </div>
    );
}

export const CompanyInfo = ({company}) => {
    const [imgSrc, setImgSrc] = useState(younifai_logo);
    const { setModalState } = modalStore(); 
    const {companies, addCompany, activeCompany, setActiveCompany, getCompanyName} = useCompanyStore();

    const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    } = useDropzone({
            onDrop: async (files) => {
                let formData = new FormData();
                formData.append('file', files[0]);
            let endpoint = `companies/${company.id}/add_logo`;
                await Api.getData(endpoint, formData, 'file').then( (response) => {
                    if(response.data.success){
                        getLogo();
                    }

                }).catch(async (error) => {
                    console.log(error);
                });
            }
      });
    
    const getLogo = async (company_id) => {
        let endpoint = `companies/${company.id}/get_logo_url`;
        const content = Api.getData(endpoint, [], 'get').then( function (response) {
            // let loaded_img = new Image();
            // loaded_img.src = 
            // const base_64 = Buffer.from(response.data, 'binary').toString('base64');
            setImgSrc(Api.base_url+response.data.url);
        }).catch(async (error) => {
            // 
            console.log(error);
        });
    }

    const showUsersModal = () => {
            
            setModalState({
                title: "Gebruikers",
                modalContent: <UsersListModal />,
                showModal: true,
                size: "large"
            });
        
    }
    const showCompanyModal = async ({company}) => {
            setModalState({
                title: "Bedrijfsgegevens bewerken",
                modalContent: <CompanyForm company={company} />,
                showModal: true,
                size: "large"
            });
    }
    const showConfigModal = async ({company}) => {
        let config = null;
        // get config for company
        let endpoint = `companies/${company.id}/configs/`;

        let result = await Api.getData(endpoint, [], 'get').then(function(response) {
            config = response.data[0];
        });
        setModalState({
            title: `Technische configuratie voor ${company.name}`,
            modalContent: <CompanyConfigForm company={company} config={config} />,
            showModal: true, 
            size: "large"
        })
    }

    const showConfigCard = ({company}) => {
        let is_owner = true;
        if (is_owner) {
            return (
                <SmallCard onClick={(event) => {showConfigModal({company})}}> 
                    <Cardtitle>Technische configuratie</Cardtitle>
                </SmallCard>
            )
        } else {
            return false;
        }
    }

    useEffect( () => {
        getLogo(company.id);
    }, [company])

    return (
        <Cardgroup>
            <SmallCard onClick={(event) => {showCompanyModal({company})}} >
                <Cardtitle>{companies[company.id].name}</Cardtitle>
                <Cardtitle>{companies[company.id].email}</Cardtitle>
                <Cardtitle>{companies[company.id].street} {companies[company.id].house_number}, {company.zip_code}</Cardtitle>
            </SmallCard>
            <SmallCard  onClick={(event) => {showUsersModal()}} >
                <Cardtitle>Gebruikersbeheer</Cardtitle>
            </SmallCard>
            { showConfigCard({company}) }
            <SmallCard >
                <ImageWrapper {...getRootProps()}>
                    <input {...getInputProps()} />
                    <img className="company-logo" src={imgSrc} />
                </ImageWrapper >
            </SmallCard>
        </Cardgroup>
    )

}

export const Company = ({props}) => {
    const {companies, addCompany, activeCompany, setActiveCompany} = useCompanyStore();
    const {clearLocations} = useLocationStore();
    const navigate = useNavigate();
    const {logoSrc, setLogoSrc} = useInterfaceStore();
    const [imgSrc, setImgSrc] = useState(younifai_logo);
    const {clearWorkflows} = workflowStore(); 

    const handleActiveCompany = (company_id) => {
        setActiveCompany(company_id);
        clearLocations();
        setLogoSrc(imgSrc);
        clearWorkflows();
        let new_location = nextStep.getNext();
        navigate(new_location);
    }
    const gotoCompany = (company_id) => {
        setActiveCompany(company_id);
        clearLocations();
        setLogoSrc(imgSrc);
        clearWorkflows();
        navigate(`/company/${company_id}`)

    }

    // get image src from API, API is sucerd with JWT tokens so we need to request an url secured with a token
    //
    const getLogo = async (company_id) => {
        if(!props.logo ) {
            return false;
        } 
        let endpoint = `companies/${company_id}/get_logo_url`;
        const content = Api.getData(endpoint, [], 'get').then( function (response) {
            // let loaded_img = new Image();
            // loaded_img.src = 
            // const base_64 = Buffer.from(response.data, 'binary').toString('base64');
            setImgSrc(Api.base_url+response.data.url);
        }).catch(async (error) => {
            // 
            console.log(error);
        });
    }
    useEffect( () => {
        getLogo(props.id);
    }, [props])
    let activeClass = '';
    if (props.id == activeCompany) {
        activeClass = 'active';
    }


    return (
        <CompanyItem className={activeClass}  >
        <ImageWrapper onClick= {(event) => handleActiveCompany(props.id)} >
            <img className="company-logo" src={imgSrc} />
        </ImageWrapper >
        <InfoWrapper onClick={(event) => gotoCompany(props.id)}>
            <ListItemLabel>Naam</ListItemLabel>
            <span >{props.name}</span>
        </InfoWrapper>
        </CompanyItem>
    )
}

