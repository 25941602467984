import React from 'react';
import styled from 'styled-components';
import Api from '../helpers/api';
import nextStep from "../helpers/nextStep";
import { useNavigate } from "react-router-dom";

import {useLocationStore, useInterfaceStore, useFilesStore} from '../store';
import sendIcon from '../assets/send-icon-min.svg';
import arrowDown from '../assets/arrow-down-min.svg';

import {PageWrapper,
    SectionTitle, 
    InlineButton
} from '../ui_components/general';

const ErrorText = styled.div`
    position: absolute;
    color: red;
    top: -100%;
    border: 1px solid red;
    background-color: rgba(255, 255, 255, 0.9);
    width: fit-content;
    border-radius: 8px;
    padding: 10px;
`;
const ToggleButton = styled.button`
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    background-color: transparent;
    background-image: url(${arrowDown});
    width: 35px;
    height: 35px;
    border: none;
    cursor: pointer;
`;

const ListItem = styled.div`
    width: 100%;
    padding: 20px;
    margin: 20px;
    display: flex;
`;

const ListItemCel = styled.div`
    margin: 0 20px;
    color:  #00DFA3;
    border-bottom: 1px solid #00DFA3;
    
    &:nth-child(2),
    &:nth-child(1) {
        width: 15%;
    }
    &:nth-child(3) {
        width: 66%;
    }
    .dark & {
        color: var(--you-purple);
    }
`;
const ListItemLabel = styled.div`
    color: #4991DE;
    font-size: 10px;
`;

const Form = styled.form`
    width: 80%;
    display: flex;
    padding: 20px;
    margin: 20px;
    position: relative;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.3));
`;

const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  border:none;
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0.3);
  &::placeholder,
  &::-webkit-input-placeholder {
    color: #2371CB;
    opacity: 1;
  }
  :-ms-input-placeholder {
     color:  #2371CB;
     opacity: 1;
  }
  &:focus {
    outline: none !important;
  }
`;

const SelectOption = styled.span`
  color:#00DFA3;  
  font-size: 18px;
  `;

const AskButton = styled.button`
    position: absolute;
    display: block;
    background-color: #2371CB;
    color: #ffffff;
    background-image: url(${sendIcon});
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 17px 18px;
    width: fit-content;
    height: fit-content;
    border-radius: 3em;
    border: none;
    font-size: 14px;
    padding: 12px;
    left: 100%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
        filter: brightness(1.1);
    }
    &:disabled {
        filter: brightness(0.75);
    }
`;


const TypeList = styled.div`
    background-color: #ffffff;
    z-index: 9;
    color: var(--you-purple);
    font-size: 22px;
    max-height: 0;
    overflow: hidden;
    position: absolute;
    top: 3em;
    transition: all 0.3s ease-out;
    overflow-y: auto;
    height: auto;
    &.active {
    max-height: 30vh
    }
`;
const TypeListItem = styled.div`
    display: block;
    padding: 12px;
    border-bottom: 1px solid var(--you-purple);
    cursor: pointer;
    &:hover {
        color: var(--you-green);
    }
`;
const DropDown = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
`;



const IndexLocation = ({props}) => {
    
    const {indexLocations, addLocation, setActiveLocation } = useLocationStore();
    const { closeDrawer, setReload } = useInterfaceStore();
    const { clearFiles } = useFilesStore();
    const navigate = useNavigate();
    
    const handleActiveLocation = (location_id) => {
        setActiveLocation(location_id);
        let new_location = nextStep.getNext();
        navigate(new_location);
        setReload(true);
        closeDrawer();
    }
    const handleFileView = (location_id) => {
        setActiveLocation(location_id);
        clearFiles();
        navigate('/files')
        closeDrawer();
    }
    return (
        <li className="list-item" >
            <span style={{"grid-column": "1/3"}}>{props.name}</span>
            <span>{props.type}</span>
            <span>
                <InlineButton className="align-left" onClick= {(event) => handleActiveLocation(props.id)} >Activeren</InlineButton>
                <InlineButton className="align-right" onClick= {(event) => handleFileView(props.id)} >Bestanden</InlineButton>
            </span>
        </li>
    )
}

const IndexLocationForm = ({active_location}) => {
    const defaultName = (active_location ? active_location.name : '')
    const defaultType = (active_location ? active_location.type : 'documents')
    const defaultPath = (active_location ? active_location.path : '')
    const {indexLocations, addLocation} = useLocationStore();
    
    

    const [disabledButton, setDisabledButton] = React.useState(true);
    const [name, setName] = React.useState(defaultName);
    const [type, setType] = React.useState(defaultType);
    const [path, setPath] = React.useState(defaultPath);
    const [showList, setShowList] = React.useState('');
    const [showUrlError, setShowUrlError] = React.useState('');


    const handleTypeSwitch = async (type) => {
        setType(type);
        setShowList(false);
    }

    const toggleTypeList = (e) => {
        e.preventDefault();
        if(showList) {
            setShowList(false);
        } else {
            setShowList(true);
        }
    }
    
    let endpoint = 'index_locations'; 
    let method = 'post';
    if (active_location) {
        endpoint += '/'+active_location.id;
        method = 'put';
    }

    const handleChange = (event) => {
        if (event.target.name == 'name') {
            setName(event.target.value);
        }
        if (event.target.name == 'type') {
            setType(event.target.value);
        }
        if (event.target.name == 'path') {
            setPath(event.target.value);
        }

        if (name
        && type ) {
            if (type == 'url'
            && path
            ) {
                setDisabledButton(false);
            } else {

                setDisabledButton(false);
            }
        } else {
            setDisabledButton(true);
        }
    } 

    const handleSubmit = async (event) => {
        event.preventDefault();

        let url;
        try {
            url = new URL(path);
        } catch(e) {
            setShowUrlError(true);
            return false;
        }

        setShowUrlError(false);

        if (name
        && type
        && path ){
            let data = {
                'name' : name,
                'type' : type,
                'path' : path
            };
            let response = await Api.getData(endpoint, data, method).then( (response) => {
                data['id'] = response.data.id;
                // refresh form
                if (method == 'post') {
                    addLocation(data);
                } else {
                    // @TODO implement updateLocation
                }
                setName(defaultName);
                setType(defaultType);
                setPath(defaultPath);
            });
        }
    }

    const possibleTypes = ["url", "documents"];

    return (
        <Form autoComplete="off" onSubmit={(event) => { handleSubmit(event)}}>
            <ListItemCel>
                <ListItemLabel>Naam</ListItemLabel>
                <Input 
                    onChange= {(event) => handleChange(event)}
                    onFocusOut = {(event) => handleChange(event)}
                    name="name" 
                    value= {name}
                />
            </ListItemCel>
            <ListItemCel>
                <ListItemLabel>Type</ListItemLabel>
                <DropDown>
                    <SelectOption>{type}<span style={{position:'relative'}}><ToggleButton onClick={(event) => {toggleTypeList(event)}}></ToggleButton></span></SelectOption>
                    <TypeList className={(showList ? 'active' : '')}>
                        {possibleTypes.map((type) => (
                            <TypeListItem onClick={(event) => {handleTypeSwitch(type)}} key={type}>{type}</TypeListItem>
                        ))}
                    </TypeList>
                </DropDown>
            </ListItemCel>
        { (type == 'url'? 
            <ListItemCel>
                <ListItemLabel>Pad</ListItemLabel>
                <Input 
                    name="path" 
                    onChange= {(event) => handleChange(event)}
                    onFocusOut = {(event) => handleChange(event)}
                    onPaste =  {(event) => handleChange(event)}
                    value= {path}
                />
            {(showUrlError ? <ErrorText>Dit is een ongeldige url. Een url moet beginnen met http: of https: </ErrorText> : '') }
            </ListItemCel>
        : '')}
            <AskButton disabled={disabledButton} type="submit">{(active_location ? 'Opslaan' : 'Toevoegen')}</AskButton>
        </Form>

    )
}

export {IndexLocation, IndexLocationForm};
