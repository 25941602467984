import React from 'react';

import {useCompanyStore, useLocationStore, useInterfaceStore} from '../store';
import { useNavigate } from "react-router-dom";

class nextStep {

    static getNext = () => {
        // check active company
        // no company send to company screen
        // check active index id
        // no active index, send to company-index screen
        
        const activeCompany = useCompanyStore.getState().activeCompany;
        const activeLocation = useLocationStore.getState().activeLocation;
        const lastKnownScreen = useInterfaceStore.getState().lastKnownScreen;
        if (activeCompany && lastKnownScreen) {
            return lastKnownScreen;
        }
        if (activeCompany) {
            return `/company/${activeCompany}`;
        }
        else {

            // if (!activeCompany) {
            return '/companies'
        } 
    /*
        else if (!activeLocation) {
            return '/profile'
        } else {
            return '/chat'
        }
        */
    }
}

export default nextStep;
