import React from 'react';
import { useRef, useEffect } from 'react';
import { Tooltip } from 'react-tooltip'
import styled from 'styled-components';

import {useAnswerStore} from '../store';
import Answer from './answer';

const Answerlist = styled.div`
    position: relative;
    display: flex;
    align-content: flex-end;
    flex-wrap: wrap;
`;
const AlwaysScrollToBottom = () => {
  const elementRef = useRef();
  useEffect(() => elementRef.current.scrollIntoView());
  return <div ref={elementRef} />;
};


const AnswerList = () => {
    const {answers, addAnswer} = useAnswerStore();
    return (
        <>
        <Answerlist>
            {answers && answers.map((answer) => (
                <Answer key={answer.id} answer={answer} className="answer-entry" />
            ))}
            <AlwaysScrollToBottom />
        </Answerlist>
        <Tooltip clickable openOnClick id="answer-tooltip" className="sources_tooltip" />
        </>
    );
};

export default AnswerList;
