import React from 'react';
import styled from 'styled-components';

import {useUserStore} from '../store';
import {useInterfaceStore} from '../store';
import Api from '../helpers/api';
import { useNavigate } from "react-router-dom";
import menuIcon from '../assets/menu_icon-min.svg';

const Profilebutton = styled.button`
    background-color: transparent;
    border: 1px solid #00DFA3;
    border-radius: 3em;
    width: 35px;
    height: 35px;
    color: #00DFA3;
`;

const Loginbutton = styled.button`
    background-color: transparent;
    border: 1px solid #00DFA3;
    border-radius: 3em;
    width: 35px;
    height: 35px;
    color: #00DFA3;
`;
const Settingsbutton = styled.button`
    background-color: transparent;
    border: 1px solid #00DFA3;
    border-radius: 3em;
    width: 35px;
    height: 35px;
    color: #00DFA3;
    background-image: url(${menuIcon});
    background-repeat: no-repeat;
    background-size: 15px 11px;
    background-position: center;
`;


export const ProfileButton = () => {
    const initials = useUserStore((state) => state.initials);
    const navigate = useNavigate();

    const handleProfileClick = (event) => {
        navigate('/profile');
    }
    const handleLoginClick = (event) => {
        navigate('/login');
    }


    if (initials.length > 1 ) {
        return (
            <Profilebutton onClick={(event) => {handleProfileClick(event)}} >{initials}</Profilebutton>
        );
    } else {
        return <Loginbutton onClick={(event) => {handleLoginClick(event)}} >LI</Loginbutton>
    }


};

export const SettingsButton = () => {

    const {showDrawer, openDrawer, closeDrawer } = useInterfaceStore();
    const handleSettingsClick = (event) => {
        if (showDrawer) {
            closeDrawer();
        } else {
            openDrawer();
        }
    }
    return (
        <Settingsbutton onClick={(event) => {handleSettingsClick(event)}} ></Settingsbutton>
    )

}
