import React, {useEffect} from 'react';
import styled from 'styled-components';
import Api from "../helpers/api";
import { useNavigate } from "react-router-dom";

import {useInterfaceStore} from '../store';

import loadingIcon from '../assets/loading_infinite-min.svg';
import nextStep from "../helpers/nextStep";

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100vh;
    background-color: var(--you-purple);
    flex-wrap: wrap;
`;
const Loader = styled.div`
    width: 234px;
    height: 234px;
    background-image: url(${loadingIcon});
    background-repeat: no-repeat;
    display: block;
`;

const Loading = () => {
    
    const {loading, setLoading} = useInterfaceStore();
    const navigate = useNavigate();

    const tryRefresh = async () => {
        if (Api.hasRefreshToken()) {

            const logged_in = await Api.refreshToken();
            // hide login on first go? 
            // maybe show loading screen?
            if (logged_in) {
                // @TODO 
                // get user info
                // set to store
                let new_location = nextStep.getNext();
                navigate(new_location);
                setLoading();
            } else {
                navigate('/login/');
            }
         } else {
            // show loging
            navigate('/login/');
        }
    }

    useEffect(() => {
        tryRefresh();  
    });

    return (
        <Wrapper>
            <Loader />
            <div style={{"text-align":"center", "width": "100%"}}> We zijn voor je bezig... </div>
        </Wrapper>
    )
}
export default Loading;
