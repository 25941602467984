import React, {useEffect, useState} from 'react';
import Api from '../helpers/api';
import {AddStepForm} from './forms/addstepform';
import {EditStepForm} from './forms/editstepform';

import sendIcon from '../assets/send-icon-min.svg';
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";
import { modalStore } from '../store';


import {PageCenterWrapper, 
    FourColGrid,
    SectionTitle, 
    ListItem,
    ListItemCel, 
    ListItemLabel, 
    Input, 
    ListWrapper,
    Dropdown,
    DropdownList,
    DropdownListItem,
    DropdownToggle,
    ModalContent,
    InlineButton
} from '../ui_components/general';


export const AskButton = styled.button`
    display: block;
    background-color: #2371CB;
    color: #ffffff;
    background-image: url(${sendIcon});
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 17px 18px;
    width: fit-content;
    height: fit-content;
    border-radius: 3em;
    border: none;
    font-size: 14px;
    padding: 12px;
    left: 100%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
        filter: brightness(1.1);
    }
    &:disabled {
        filter: brightness(0.75);
    }
    .submitted & {
    }
`;

export const Form = styled.form`
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    margin: 20px;
    position: relative;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.3));
    border: 1px solid var(--you-green);
`;
const Steplist = styled.div`
    position: relative;
    flex-wrap: wrap;
    & .dark {
        max-width: 60vw;
    }
`;
const Textarea = styled.textarea `
    color: var(--you-green);
    resize: none;
    background-color: rgba(255,255,255,0.3);
    padding: 10px;
`;

export const StepListItem = ({step}) => {
    const { setModalState } = modalStore(); 

    const showStepEditModal = (step) =>  {
        setModalState({
            size: "large",
            title: `Stap ${step.name} bewerken`,
            modalContent: <EditStepForm step={step} />,
            showModal: true
        });
    } 

    // on click stepForm shoul apear in the modal. 
    return (
        <li className="list-item" onClick={(event)=> {showStepEditModal(step)}}>
            <span style={{"grid-column": "1/3"}}>{step.name}</span>
        </li>
    )
}

const StepTypePickerForm = ({workflow}) => {
    const [showStepTypeList, setShowStepTypeList] = useState(false);
    const [stepType, setStepType] = useState("");

    const possible_step_types = [
        {"name": "Sitemap" , "type": 'map_site'},
        {"name": "Loop" , "type": 'iterator'},
        {"name": "API verzoek" , "type": 'api_cal'},
        {"name": "Prompt" , "type": 'prompt'},
        {"name": "Output" , "type": 'output'}
    ];

    const toggleStepTypeList = () => {
        if(showStepTypeList) {
            setShowStepTypeList(false);
        } else {
            setShowStepTypeList(true);
        }
    }

    const handleStepTypeSwitch = (event, type) => {
        event.preventDefault();
        setStepType(type);
        toggleStepTypeList();
    }

    return (
        <ModalContent>
            <Dropdown>
                <SectionTitle>{(stepType ? stepType : "Kies een type" )}<span style={{position:'relative'}}><DropdownToggle onClick={(event) => {toggleStepTypeList()}}></DropdownToggle></span></SectionTitle>
                
              <DropdownList className={(showStepTypeList ? 'active' : '')}>
                {possible_step_types && possible_step_types.map((step_type, index) => (
                    <DropdownListItem onClick={(event) => {handleStepTypeSwitch(event, step_type.type)}} key={index}>{step_type.name}</DropdownListItem>
                ))}
              </DropdownList>
            </Dropdown>
            <AddStepForm type={stepType} workflow_id={workflow.id} />
        </ModalContent>
    )
}


export const StepList = ({className, workflow}) => {
    const navigate = useNavigate();

    const { setModalState } = modalStore(); 
    const getSteps = async () => {
        let endpoint = `workflows/${workflow.id}/steps/`;
        let result = await Api.getData(endpoint, [], 'get').then((response) => {
            setSteps(response.data);
        }).catch( async (error) => {
            if (error.response.status == 401) {
                let result = await Api.refreshToken().then((succes) => {
                    if (succes) {
                        // retry request
                        getSteps();
                    } else {
                        // navigate to login
                        navigate('/login');
                    }
                }).catch((error) => {
                    // navigate to login
                    navigate('/login');
                });
            }
        });
    }

    const addStep = async (event) => {

        // choose step type. 
        setModalState({
            size: "large",
            title: `Stap toevoegen aan ${workflow.name}`,
            modalContent: <StepTypePickerForm workflow={workflow} />,
            showModal: true
        });
    }

    const [steps, setSteps] = useState([]);

    useEffect( () => {
        getSteps();
    }, []);
    return (
        <Steplist className={className} > 
            <SectionTitle >Stappen</SectionTitle>
            {steps && 
                steps.map((step) => (
                /*<StepForm step={step} />*/
                    <StepListItem key={step.id} step={step} />
                ))}
            <InlineButton onClick={(event) => addStep(event)}>Stap Toevoegen</InlineButton>
        </Steplist>
    )
}
