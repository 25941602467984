// this file should be a collection of usefull formatting functions
//

class FormatDate {
    // format date as string 
    // please use this througout the program so 
    // we can tweak it centrally. 
    // This is used in stead opf moment.js because at this point all we need is ne string
    formatted(date_string) {
        this.date = date_string;
        const date = new Date(this.date);
        const yyyy = date.getFullYear();
        let mm = date.getMonth() + 1; // Months start at 0!
        let dd = date.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        const formattedDate = dd + '-' + mm + '-' + yyyy;
        return formattedDate;

    }
}

/*  
 *  nl2br
 *  adds breaks as nl characters. 
 * */

class Nl2br {
    nl2br (str, is_xhtml) {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }   
}

export {FormatDate, Nl2br};
