import React, {useState} from 'react';
import styled from 'styled-components';
import Api from '../helpers/api';
import nextStep from "../helpers/nextStep";
import { useNavigate } from "react-router-dom";

import sendIcon from '../assets/send-icon-min.svg';
import arrowDown from '../assets/arrow-down-min.svg';

import {modalStore } from '../store';

import {PageCenterWrapper, 
    FourColGrid,
    SectionTitle, 
    ListItemCel, 
    ListItem, 
    ListItemLabel, 
    Input,
    Textarea,
    Form, 
    AskButton,
    ButtonsSection,
    ToolbarButton,
    ListWrapper
} from '../ui_components/general';

const ToggleButton = styled.button`
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    background-color: transparent;
    background-image: url(${arrowDown});
    width: 35px;
    height: 35px;
    border: none;
    cursor: pointer;
`;

const Checkbox = styled.input `

`;
const SelectOption = styled.span`
  color:#00DFA3;  
  font-size: 18px;
  `;


const ScopeList = styled.div`
    background-color: #ffffff;
    z-index: 9;
    color: var(--you-purple);
    font-size: 22px;
    max-height: 0;
    overflow: hidden;
    position: absolute;
    top: 3em;
    transition: all 0.3s ease-out;
    overflow-y: auto;
    height: auto;
    &.active {
    max-height: 30vh
    }
`;
const ScopeListItem = styled.div`
    display: block;
    padding: 12px;
    border-bottom: 1px solid var(--you-purple);
    cursor: pointer;
    &:hover {
        color: var(--you-green);
    }
`;
const DropDown = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
`;


export const Prompt = ({props}) => {
    
    const handleActivePrompt = (prompt_id) => {
        console.log('hiero');
    }
    return (
        <ListItem >
            <ListItemCel>
                <ListItemLabel>Toon</ListItemLabel>
                <span>{props.tone}</span>
            </ListItemCel>
            <ListItemCel>
                <ListItemLabel>Rol</ListItemLabel>
                <span>{props.role}</span>
            </ListItemCel>
            
            <div className="buttons_wrapper" style={{position:'relative'}}>
                <AskButton onClick= {(event) => handleActivePrompt(props.id)} >Activeren</AskButton>
                { 
                    //<AskButton style={{left: "100px"}} onClick= {(event) => handleFileView(props.id)} >Bestanden</AskButton>
                }
            </div>
        </ListItem>
    )
}

export const PromptForm = ({prompt}) => {

    let endpoint = 'prompts/'; 
    if (prompt) {
        endpoint = `prompts/${prompt.id}`;
    }
    const defaultRole = (prompt ? prompt.role : '')
    const defaultTone = (prompt ? prompt.tone : '')
    const defaultScope = (prompt ? prompt.scope : 'all')
    const defaultName = (prompt ? prompt.name : '')
    const defaultDefault = (prompt ? prompt.default : false)

    const [showList, setShowList] = React.useState('');
    const [role, setRole] = React.useState(defaultRole);
    const [name, setName] = React.useState(defaultName);
    const [tone, setTone] = React.useState(defaultTone);
    const [scope, setScope] = React.useState(defaultScope);
    const [defaultPrompt, setDefaultPrompt] = React.useState(defaultDefault);
    const { setModalState } = modalStore(); 

    const [disabledButton, setDisabledButton] = React.useState(true);

    const handleChange = (event) => {
        if (event.target.name == 'role') {
            setRole(event.target.value);
        }
        if (event.target.name == 'name') {
            setName(event.target.value);
        }
        if (event.target.name == 'tone') {
            setTone(event.target.value);
        }
        if (event.target.name == 'defaultPrompt') {
            setDefaultPrompt(event.target.checked);
        }
        /*
        if (event.target.name == 'scope') {
            setScope(event.target.value);
            toggleScopeList();
        }
        */
        setDisabledButton(false);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        // maybe filter to only use actively changed data? 
        // use state savedata perhaps
        let data = {
            'role': role,
            'scope': scope,
            'tone': tone,
            'name': name,
            'default': defaultPrompt 
        };
    
        let response = await Api.getData(endpoint, data, 'post').then( (response) => {
            // update prompt in store 
            setModalState({showModal: false});
        });
        console.log('submitting');
    }

    const toggleScopeList = (e) => {
        e.preventDefault();
        if(showList) {
            setShowList(false);
        } else {
            setShowList(true);
        }
    }

    const handleScopeSwitch = async (scope) => {
        console.log(scope);
        setScope(scope);
    }
    const possibleScopes = ["all", "index"];

    return (
        <>
        <Form id="prompt_form" autoComplete="off" onSubmit={(event) => { handleSubmit(event)}}>
            <div className="formrow">
                <ListItemCel>
                    <ListItemLabel>Default</ListItemLabel>
                    <Checkbox
                        type="checkbox"
                        label=""
                        name="defaultPrompt"
                        checked={defaultPrompt}
                        onChange={(event) => handleChange(event)}
                    />
                </ListItemCel>
                <ListItemCel>
                    <ListItemLabel>Scope</ListItemLabel>
                    <select value={scope} onChange={(event) => {handleScopeSwitch(event.target.value)}}>
                    {possibleScopes.map((scope_option) => (
                        <option value={scope_option}>{scope_option}</option>
                            ))}
                    </select>
                </ListItemCel>
            </div>
            <ListItemCel>
                <ListItemLabel>Naam</ListItemLabel>
                <Input 
                    onChange= {(event) => handleChange(event)}
                    onFocusOut = {(event) => handleChange(event)}
                    name="name" 
                    value= {name}
                />
            </ListItemCel>
            <ListItemCel>
                <ListItemLabel>Rol</ListItemLabel>
                <Textarea 
                    rows="4"
                    cols="50"
                    onChange= {(event) => handleChange(event)}
                    onFocusOut = {(event) => handleChange(event)}
                    name="role" 
                    value= {role}
                />
            </ListItemCel>
            <ListItemCel>
                <ListItemLabel>Toon</ListItemLabel>
                <Textarea 
                    rows="8"
                    cols="70"
                    onChange= {(event) => handleChange(event)}
                    onFocusOut = {(event) => handleChange(event)}
                    name="tone" 
                    value= {tone}
                />
            </ListItemCel>
        </Form>
        <ButtonsSection>
            <ToolbarButton onClick={(event) =>setModalState({showModal: false}) }>Annuleren</ToolbarButton>
        
            <span></span>
            <span></span>
            <span></span>
            <ToolbarButton form="prompt_form" className="align-right">Opslaan</ToolbarButton>
        </ButtonsSection>
        </>
    )
}

export const PromptModal = () => {
    const [fadein, setFadein] = useState('fade-in');

    return (
        <div className={fadein +" modal-content"}>
            <PromptForm />
        </div>
    )
}
