import React from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
// import logo from './assets/alt-logo-min.svg';

import './App.css';
import 'react-toastify/dist/ReactToastify.min.css';
import HomePage from './pages/homepage';
import {Chat} from './pages/chat';
import Loading from './pages/loading';
import Dashboard from './pages/dashboard';
import Companies from './pages/companies';
import Company from './pages/company';
import Workflow from './pages/workflow';
import PassReset from './pages/password-reset';
import OptionDrawer from './components/optiondrawer';
import {Modal} from './components/modal';
import FilesOverview from './pages/filesoverview';
import Login from './pages/login';
import Profile from './pages/profile';
import {ProfileButton, SettingsButton} from './components/profilebutton';
import {modalStore, useInterfaceStore} from './store';

function App() {
    // try to figure out logical next step. 
  return (
    <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element = {<Loading />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/files" element={<FilesOverview />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/login" element={<Login />} />
          <Route path="/companies" element={<Companies />} />
          <Route path="/company/:id" element={<Company />} />
          <Route path="/workflow/:id" element={<Workflow />} />
          <Route path="/password-reset/:token?" element={<PassReset />} />
          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>

  );
}

function Layout() {

    const { modalState,setModalState } = modalStore(); 
    const {title, logoSrc} = useInterfaceStore();
    return (
        <div>
            <div className="header-bar">
                {/* A "layout route" is a good place to put markup you want to
                  share across all the pages on your site, like navigation. */}
                <div className="top-bar">
                <Link className="logo_link" to="/"><img  src={logoSrc} alt="Logo" /></Link>
                <nav>
                    <ul>
                        <li>
                            <ProfileButton />
                        </li>
                        <li>
                            <SettingsButton />
                        </li>
                    </ul>
                </nav>
                </div>
                <div className="page-title"><h1>{title}</h1></div>
            </div>
            <OptionDrawer></OptionDrawer>
            <Modal state={modalState} setModalState={setModalState} />
            {/* An <Outlet> renders whatever child route is currently active,
              so you can think about this <Outlet> as a placeholder for
              the child routes we defined above. */}
            <Outlet />
            <ToastContainer />
            <div className="backdrop"></div>
        </div>
    );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to starting page</Link>
      </p>
    </div>
  );
}


export default App;

