import React from 'react';
import {useState, useEffect} from 'react';
import Api from "../helpers/api";
import styled from 'styled-components';

import {modalStore, useCompanyStore, useInterfaceStore} from '../store';

import {
    SectionTitle, 
    AreaTitle,
    ListItemCel, 
    ListWrapper,
    ListItem, 
    ListItemLabel, 
    Form, 
    Input, 
    InputWrapper,
    AskButton,
    Toolbar,
    ToolbarButton,
    InlineButton,
    ButtonsSection,
    Description,
} from '../ui_components/general';


export const UsersListModal = () => {
    
    const [fadein, setFadein] = useState('fade-in');
    const endpoint = 'users/';
    const [users, setUsers] = useState([]);
    const { setModalState } = modalStore(); 

    const closeModal = () => {
        setModalState({showModal: false});
    }

    const getUsers = async () => {
        let response = await Api.getData(endpoint, [], 'get').then( (response) => {
            console.log(response);
            if (response.data 
            && response.data.length > 0) {
                setUsers(response.data);
            }
        }).catch(async (error) => {
            console.log(error);
        });
    }
    const addUserModal = async () => {
        // set Modal state to add user
        setModalState({
            title: "Gebruiker toevoegen",
            modalContent: <EditUserModal user={{}} />,
            showModal: true,
            size: "large"
        });
    }
    const editUserModal = async (data) => {
        setModalState({
            title: "Gebruiker toevoegen",
            modalContent: <EditUserModal user={data.user} />,
            showModal: true,
            size: "large"
        });
    }

    const removeUser = async (data) => {
        let user = data.user;

        console.log('verwijder gebruiker: ', user.id, user.firstname);
        let endpoint = `users/${user.id}`;
        let response = await Api.getData(endpoint, [], 'delete').then( (response) => {
            // update prompt in store 
            setModalState({showModal: false});
        }).catch(async(error) => {
            console.log(error);
        });
    }

    useEffect( () => {
        getUsers();
    }, [])

    return (
        <div className={fadein +" modal-content"}>
            <ul className="list">
            {users && users.map((user) => (
                <li className="list-item" key={user.id}> 
                {user.firstname} {user.lastname} 
                <span>{user.email}</span>
                <InlineButton onClick={(event) => {editUserModal({user})}}>Bewerken</InlineButton>
                <InlineButton onClick={(event) => {removeUser({user})}}>Verwijderen</InlineButton>
                </li>
            ))}
            </ul>
            <ButtonsSection>
                <ToolbarButton className="align-left" onClick={(event) => closeModal()}>Sluiten</ToolbarButton>
                <ToolbarButton className="align-right" onClick={(event) => addUserModal()}>Gebruiker toevoegen</ToolbarButton>
            </ButtonsSection>
        </div>
    )
}

export const EditUserModal = ({user}) => {
    const { setModalState } = modalStore(); 

    const defaultEmail = (user ? user.email : '')
    const defaultFirstname = (user ? user.firstname : '')
    const defaultLastname = (user ? user.lastname : 'all')

    const [showList, setShowList] = React.useState('');
    const [firstname, setFirstname] = React.useState(defaultFirstname);
    const [lastname, setLastname] = React.useState(defaultLastname);
    const [email, setEmail] = React.useState(defaultEmail);

    const [disabledButton, setDisabledButton] = React.useState(true);

    const closeModal = () => {
        setModalState({showModal: false});
    }

    const handleChange = (event) => {
        if (event.target.name == 'email') {
            setEmail(event.target.value);
        }
        if (event.target.name == 'firstname') {
            setFirstname(event.target.value);
        }
        if (event.target.name == 'lastname') {
            setLastname(event.target.value);
        }
        /*
        if (event.target.name == 'scope') {
            setScope(event.target.value);
            toggleScopeList();
        }
        */
        setDisabledButton(false);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        // maybe filter to only use actively changed data? 
        // use state savedata perhaps
        let data = {
            'firstname': firstname,
            'lastname': lastname,
            'email': email,
        };

        let endpoint = (user.id ? `users/${user.id}` : 'users/');
    
        let response = await Api.getData(endpoint, data, 'post').then( (response) => {
            // update prompt in store 
            setModalState({showModal: false});
        });
        console.log('submitting');
    }
    let save_text = (user.id ? "Opslaan" : "Toevoegen");


    return (
        <>
        <Form id="user_form" autoComplete="off" onSubmit={(event) => { handleSubmit(event)}}>
            <ListItemCel>
                <ListItemLabel>Voornaam</ListItemLabel>
                <Input 
                    onChange= {(event) => handleChange(event)}
                    onFocusOut = {(event) => handleChange(event)}
                    name="firstname" 
                    value= {firstname}
                />
            </ListItemCel>
            <ListItemCel>
                <ListItemLabel>Achternaam</ListItemLabel>
                <Input 
                    onChange= {(event) => handleChange(event)}
                    onFocusOut = {(event) => handleChange(event)}
                    name="lastname" 
                    value= {lastname}
                />
            </ListItemCel>
            <ListItemCel>
                <ListItemLabel>Email</ListItemLabel>
                <Input 
                    onChange= {(event) => handleChange(event)}
                    onFocusOut = {(event) => handleChange(event)}
                    name="email" 
                    value= {email}
                />
            </ListItemCel>
        </Form>
        <ButtonsSection>
            <ToolbarButton className="align-left" onClick={(event) => closeModal()}>Annuleren</ToolbarButton>
            <span></span>        
            <span></span>        
            <ToolbarButton form="user_form" className="align-right" >{save_text}</ToolbarButton>
        </ButtonsSection>
        </>
    )
}
