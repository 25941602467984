import React, {useEffect, useState} from 'react';
import Api from '../helpers/api';
import {sleep} from '../helpers/helpers';
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";

import {workflowStore} from '../store';
import {Workflow} from './workflow';

import {
    SectionTitle, 
} from '../ui_components/general';

const Workflowlist = styled.div`
    position: relative;
    flex-wrap: wrap;
    & .dark {
        max-width: 60vw;
    }
`;
export const GetWorkflows = () => {
    // const { workflows, addWorkflows } = workflowStore(); 
    const workflows = workflowStore.getState().workflows;
    const addWorkflows = workflowStore.getState().addWorkflows;
    
    if (workflows.length > 0 ) {
        return true;
    }
    const getworkflowsfromAPI = async () => {
        let endpoint = 'workflows/';
        let result = await Api.getData(endpoint,[], 'get').then((response) => {
            addWorkflows(response.data);
            return true;

        }).catch(async (error) => {
            if (error.response.status == 401) {
                if (Api.state.busy) {
                    await sleep(400);
                    getworkflowsfromAPI();
                } else { 
                    let result = await Api.refreshToken().then((succes) => {
                        if (succes) {
                            // retry request
                            getworkflowsfromAPI();
                        } else {
                            // navigate to login
                            // navigate('/login');
                            return false;
                        }
                    }).catch((error) => {
                        // navigate to login
                        // navigate('/login');
                        return false;
                    });
                }
            }

        });
    }
    getworkflowsfromAPI();

};

export const WorkflowList = ({company}) => {

    const { workflows } = workflowStore(); 
    const navigate = useNavigate();


    // get all workflows from API
    
    useEffect( () => {
        async function getworkflows() {
            if (Api.state.busy) {
                // sleep untill api is ready
                await sleep(400);
            }
            await GetWorkflows();
        }
        getworkflows();

    }, [company]);

    return (
        <ul >
            {workflows && 
                // console.log(prompts)
                workflows.map((workflow) => (
                 <Workflow key={workflow.id} workflow={workflow} />
                ))
            }
        </ul>
    )
}
