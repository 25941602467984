import React, {useEffect} from 'react';
import styled from 'styled-components';
import sendIcon from '../assets/send-icon-min.svg';
import Api from '../helpers/api';
import {IndexLocation, IndexLocationForm} from './indexlocation';
import { useNavigate } from "react-router-dom";

import {useLocationStore, useInterfaceStore} from '../store';

const Locationlist = styled.div`
    position: relative;
    flex-wrap: wrap;
    & .dark {
        max-width: 60vw;
    }
`;

const AskButton = styled.button`
    position: absolute;
    display: block;
    background-color: #2371CB;
    color: #ffffff;
    background-image: url(${sendIcon});
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 17px 18px;
    width: fit-content;
    height: fit-content;
    border-radius: 3em;
    border: none;
    font-size: 14px;
    padding: 12px;
    left: 100%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
        filter: brightness(1.1);
    }
    &:disabled {
        filter: brightness(0.75);
    }
`;

const LocationsButton = styled.button`
    position: relative;
    display: block;
    background-color: #2371CB;
    color: #ffffff;
    background-image: url(${sendIcon});
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 17px 18px;
    width: fit-content;
    height: fit-content;
    border-radius: 3em;
    border: none;
    font-size: 14px;
    padding: 12px;
    margin-left: 40px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
        filter: brightness(1.1);
    }
    &:disabled {
        filter: brightness(0.75);
    }
`;

export const LocationList = (className) => {

    const navigate = useNavigate();
    const getIndexLocations = async () => {
        console.log('hier');
        let endpoint = 'index_locations';
        let data = {};
        let response = await Api.getData(endpoint, data, 'get').then( (response) => {
            let locations = response.data;
            locations.forEach(function (index_location) {
                addLocation(index_location);
            });

        }).catch( async (error) => {
            if (error.response.status == 401) {
                let result = await Api.refreshToken().then((result) => {
                    if (result) {
                        // retry request
                        getIndexLocations();
                    } else {
                        // navigate to login
                         navigate('/login');
                    }
                }).catch((error) => {
                    // navigate to login
                     navigate('/login');
                });
            }
        });

    }
    
    const {indexLocations, addLocation } = useLocationStore();
    const { loading } = useInterfaceStore();

    useEffect(() => {
        if (indexLocations.length < 1) {
            getIndexLocations();
        }
    }, [loading]);
    return (
        <Locationlist className={className.className}>
            {indexLocations && indexLocations.map((indexLocation) => (
                <IndexLocation key={indexLocation.id} props={indexLocation} />
            ))}
        </Locationlist>
    )
}

export default LocationList;
