import React from 'react';
import { toast } from 'react-toastify';
import {useState, useEffect} from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";

import Api from "../helpers/api";
import styled from 'styled-components';
import {StepList} from '../components/steplist';
import {TaskList} from '../components/tasklist';
import {TaskView} from '../components/taskview';
import {ModalContent} from '../components/taskmodal';

import {GetWorkflows} from '../components/workflowlist';
import {workflowStore, taskStore, useLocationStore } from '../store';
import {modalStore, useCompanyStore, useInterfaceStore} from '../store';

import {
    PageCenterWrapper, 
    PageSidebarWrapper, 
    FourColGrid,
    LeftColumn,
    RightColumn,
    SectionTitle, 
    AreaTitle,
    Collapse,
    ListItemCel, 
    ListItem, 
    ListItemLabel, 
    Form, 
    Input, 
    InputWrapper,
    AskButton,
    ListWrapper,
    Toolbar,
    ToolbarButton,
    ButtonsSection,
    Cardgroup,
    AreaWrapper,
    Description,
    Workspace,
    SmallCard,
    Cardtitle
} from '../ui_components/general';

const ModalFinished = ({task}) => {

    const { setModalState } = modalStore(); 
    const [fadein, setFadein] = useState('fade-in');


    const {active_task, setActiveTask} = taskStore();
    const navigate = useNavigate();
    const sleep = ms => new Promise(r => setTimeout(r, ms));

    const runTask = async () => {
        let endpoint = `tasks/execute/${task.id}`;
        let result = await Api.getData(endpoint, [], 'get').then( async (response) => {
            toast.info(`Taak ${task.name} wordt uitgevoerd`);
            await sleep(5000);
            toast.success('Taak is afgerond');
            // refresh();

        }).catch(async (error)  => {
            if (error.response.status == 401) {
                let result = await Api.refreshToken().then((succes) => {
                    if (succes) {
                        // retry request
                        runTask();
                    } else {
                        // navigate to login
                        navigate('/login');
                    }
                }).catch((error) => {
                    // navigate to login
                    navigate('/login');
                });
            } else {
                console.log('different error? ', error);
            }
        });
    }

    const closeModal = () => {
        runTask();       
        setModalState({showModal: false});
    }

    // if (task.status === 'open') {
    if (false) {
        return (
            <div className={fadein +" modal-content"}>
                <div className="step-wrapper">
                    <SectionTitle>Gelukt! </SectionTitle>
                    <Description> De taak is aangemaakt en zal zometeen snel mogelijk worden uitgevoerd! </Description>
                    <ButtonsSection>
                        <ToolbarButton className="align-right" onClick={(event) => closeModal()}>Sluiten</ToolbarButton>
                    </ButtonsSection>
                </div> 
            </div>
        )
    } else if (task.status === 'finished') {
        return (
            <div className={fadein +" modal-content"}>
                <div className="step-wrapper">
                    <SectionTitle>{task.name} is afgerond.</SectionTitle>
                    <Description>De taak is afgerond. Je kan het resultaat bekijken of een PDF genereren.</Description>
                    <ButtonsSection>
                        <ToolbarButton className="align-right" onClick={(event) => closeModal()}>Sluiten</ToolbarButton>
                    </ButtonsSection>
                </div>
            </div>
        )
    } else {
        return (
            <div className={fadein +" modal-content"}>
                <div className="step-wrapper">
                    Nieuw aangemaakte taak
                    <ButtonsSection>
                        <ToolbarButton className="align-right" onClick={(event) => closeModal()}>Sluiten</ToolbarButton>
                    </ButtonsSection>
                </div>
            </div>
        )
    }
}

// render right side function switch like the form component


const Workflow = () => {

    // const { state } = useLocation(); 
    const workflow_id = useParams().id;
    const {activeWorkflow, setActiveWorkflow, workflows } = workflowStore(); 
    const {companies, addCompany, activeCompany, setActiveCompany} = useCompanyStore();

    const { setModalState } = modalStore(); 
    const { setTitle } = useInterfaceStore(); 

    const {indexLocations, addLocation, clearLocations } = useLocationStore();
    const { loading } = useInterfaceStore();

    const navigate = useNavigate();

    const getActiveWorkflow = async () => {

        if (activeWorkflow.length > 0) {
            return true;
        }
        if (workflows.length > 0 ) {
            setActiveWorkflow(workflow_id);
            return true;
        }
        await GetWorkflows().then((response) => {
            if (response) {
                setActiveWorkflow(workflow_id);
                return true;
            } else {
                return false;
            }
        });

    }


    const createSteps = async (task) => {
            // get all requered steps;
        const get_steps_endpoint = `workflows/${activeWorkflow.id}/steps/`;

        let steps = await Api.getData(get_steps_endpoint, [], 'get').then( async (response) => {
            let steps = response.data;
            if (steps.length < 1) {
                return false;
            }
            createStep(task, steps, false);

        }).catch( async (error) => {
        });

    }
    const createStep = async (task, steps, current_step) => {
        console.log('in workflow');
        const create_steps_endpoint = `tasks/${task.id}/steps/`;
        if(current_step === false) {
            current_step = 0;
        } else {
            current_step = current_step +1;
        }
        let step = steps[current_step];
        if (step) {
            setModalState({
                title: activeWorkflow.name,
                modalContent: <ModalContent step={step} url={create_steps_endpoint} task={task} steps={steps} next_step_function={createStep} current_step={current_step} />,
                showModal: true,
                size: "large"
            });
        } else {
            setModalState({
                title: activeWorkflow.name,
                modalContent: <ModalFinished task={task} />,
                showModal: true,
                size: "large"
            });
        }
    }

    const startWorkflow = async () => {
        // create workflow task 
        let task_data = {
            name: activeWorkflow.name,
            type: 'direct',
            workflow_id: activeWorkflow.id,
            input: 'geen extra input'

        }

        const endpoint = `tasks/`;
        let workflowtask = await Api.getData(endpoint, task_data , 'post').then( async (response) => {
            // set modal with info and first task
            if (!response.data.success) {
                console.log('oh oh');
                return false;
            }
            // task created get task data 
            let task = await Api.getData(endpoint+response.data.id, [], 'get').then(async (response) => {
                let task = response.data;
                createSteps(task);
            }).catch ( async(error) => {
                console.log(error);
            })
        }).catch (async (error) => {
            console.log(error);
        });
    }

    const getIndexLocations = async () => {

        let endpoint = 'index_locations';
        let data = {};
        let response = await Api.getData(endpoint, data, 'get').then( (response) => {
            clearLocations();
            let locations = response.data;
            locations.forEach(function (index_location) {
                addLocation(index_location);
            });

        }).catch( async (error) => {
            if (error.response.status == 401) {
                let result = await Api.refreshToken().then((result) => {
                    if (result) {
                        // retry request
                        getIndexLocations();
                    } else {
                        // navigate to login
                         navigate('/login');
                    }
                }).catch((error) => {
                    // navigate to login
                     navigate('/login');
                });
            }
        });

    }


    useEffect( () => {
        const getData = async () => {
            getActiveWorkflow();
            setTitle(activeWorkflow.name);
            await getIndexLocations();
        }
        getData();

    }, [workflow_id]);
    

    const [openTaskCount, setOpenTaskCount] = useState(false);
    const [finishedTaskCount, setFinishedTaskCount] = useState(false);
    const [workspaceType, setWorkspaceType] = useState('openTasks');
    
    const renderWorkspace = ({workspaceType}) => {
        // determine what wqorkspace to render
        if (workspaceType == 'settings') {
            // render settings view
            // steps and all
            return <StepList workflow={activeWorkflow} />
        } else if (workspaceType == 'openTasks') {
            return <TaskList className="open" workflow_id={activeWorkflow.id} status="open" />
        } else if (workspaceType == 'finishedTasks') {
            return <TaskList className="open" workflow_id={activeWorkflow.id} status="finished" />
        }
    }


    return (
        <>
        <Toolbar>
            <ToolbarButton onClick= {(event) => navigate(`/company/${activeWorkflow.company_id}`)} > Terug naar bedrijfsoverzicht</ToolbarButton>
            <ToolbarButton onClick= {(event) => startWorkflow(activeWorkflow.id)} >Uitvoeren</ToolbarButton>
        </Toolbar>
        <Cardgroup>
            <SmallCard className={(workspaceType == 'settings' ? 'active' : '')} onClick={(event) => {setWorkspaceType('settings')}} >
                <Cardtitle>Stappen</Cardtitle>
            </SmallCard>
            <SmallCard className={(workspaceType == 'openTasks' ? 'active' : '')}  onClick={(event) => {setWorkspaceType('openTasks')}} >
                <Cardtitle>{openTaskCount} openstaande taken</Cardtitle>
            </SmallCard>
            <SmallCard className={(workspaceType == 'finishedTasks' ? 'active' : '')}  onClick={(event) => {setWorkspaceType('finishedTasks')}} >
                <Cardtitle>{finishedTaskCount} afgeronde taken</Cardtitle>
            </SmallCard>
        </Cardgroup>
        <AreaWrapper>
            <Workspace>
                {renderWorkspace({workspaceType})}
            </Workspace>
        </AreaWrapper>

        </>
    )
}

export default Workflow
