import React, {useState ,useEffect} from 'react';
import Api from '../helpers/api';
import nextStep from "../helpers/nextStep";

import {File, FileForm} from './file';
import {useFilesStore} from '../store';
import {useLocationStore} from '../store';
import {useCompanyStore} from '../store';

import styled from 'styled-components';
import { useNavigate } from "react-router-dom";

import {PageWrapper,
    ContainerTitle,
    SectionTitle,
    ListItemCel,
    ListItemLabel,
    Form,
    Input,
    InlineButton,
    AskButton
} from '../ui_components/general';

const Fileslist = styled.div`
    position: relative;
    flex-wrap: wrap;

`;

let fetching = false;
let loaded = false;


const ListFiles = ({file_location}) => {
    const {activeLocation} = useLocationStore();


    if (file_location.location_id == activeLocation) {
        return (
            <div>
                {file_location.files.map( (file) => (
                    <File key={file.id} file={file} />
                ))}
            </div>
        )
    } else {
        return (
            <div>Nog geen bestanden gevonden</div>
        )
    }
}
const FilesButton = styled.button`
    position: relative;
    display: block;
    background-color: #2371CB;
    color: #ffffff;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 17px 18px;
    width: fit-content;
    height: fit-content;
    border-radius: 3em;
    border: none;
    font-size: 14px;
    padding: 12px;
    margin-left: 40px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
        filter: brightness(1.1);
    }
    &:disabled {
        filter: brightness(0.75);
    }
`;

export const FilesList = () => {

    const {loading_files, setLoading, files, addFile, setActiveLocation, reload, setReload} = useFilesStore();
    const {activeCompany } = useCompanyStore();
    const navigate = useNavigate();
    // get files from store
    // define endpoint
    // get parse files list into File component
    // prevent endless fetch loop
    const loadFiles = async () => {
        let endpoint = 'files/';
        let response = await Api.getData(endpoint, {}, 'get').then( (response) => {
            setLoading(false);
            let data_files = response.data;
            data_files.forEach(function (file) {
                addFile(file);
            });
        });
    }

    useEffect( () => {
        console.log('triggered useEffect');
        if (files.length < 1
            && !loading_files
        ) {
            console.log('passed load test');
            setLoading(true);
            if (activeCompany) {
                console.log('passed company test');
                loadFiles();
            } else {
                let next_location = nextStep.getNext();
                navigate(next_location);
            }
        } else {
            setLoading(false);
        }

    },[]); 
    
    if (reload) {
        setReload(false);
        if (files.length < 1
            && !loading_files
        ) {
            console.log('passed load test');
            setLoading(true);
            if (activeCompany) {
                console.log('passed company test');
                loadFiles();
            } else {
                let next_location = nextStep.getNext();
                navigate(next_location);
            }
        } else {
            setLoading(false);
        }
 
    }
    const {activeLocation} = useLocationStore();

    const buildIndex = async () => {
        console.log(activeLocation);

    }


    return (
        <Fileslist>
            <FileForm />
            {/* <FilesButton onClick = {() => {loadFiles()}}> Bestanden ophalen </FilesButton> */ }
            <ContainerTitle>Bestanden in Kennisgebied</ContainerTitle>

            <InlineButton onClick={(event) => buildIndex() }>Build Index</InlineButton>
            <div className="files-container">
                {files && files.map((file) => (
                    <File key={file.id} file={file} />
                ))} 
            </div>
        </Fileslist>
    )
}
