import React, {useEffect, useState} from 'react';
import { toast } from 'react-toastify';
import Api from "../helpers/api";
import nextStep from "../helpers/nextStep";
import { setAuthToken } from "../helpers/setAuthToken"
import {useCredentialStore} from '../store';
import {useUserStore} from '../store';
import {useCompanyStore} from '../store';
import {useInterfaceStore} from '../store';
import { useNavigate } from "react-router-dom";
import sendIcon from '../assets/send-icon-min.svg';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 22vh;
`;
const Form = styled.form`
    width: 20vw;
    left: 50%;
    bottom: 50%;
    position: relative;
    z-index: 3;
    transform: translateX(-50%);
`;

const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  padding: 10px 40px;
  border-radius: 8px;
  box-shadow: 0 0 8px var(--shadow-color);
  border:none;
  margin-bottom: 1em;
  color: #2371CB;
  font-size: 18px;
  height: 54px;

  &::placeholder,
  &::-webkit-input-placeholder {
    color: #2371CB;
    opacity: 1;
  }
  :-ms-input-placeholder {
     color:  #2371CB;
     opacity: 1;
  }
  &:focus {
    outline: none !important;
  }
`;

const AskButton = styled.button`
    position: relative;
    display: block;
    background-color: #2371CB;
    color: #ffffff;
    background-image: url(${sendIcon});
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 17px 18px;
    width: 100%;
    height: 54px;
    border-radius: 8px;
    border: none;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
        filter: brightness(1.1);
    }
    &:disabled {
        filter: brightness(0.75);
    }
`;

const Link = styled.a`
    color: var(--link-color);
    text-align: right;
    width: 100%;
    display: block;
    margin-top: 25px;
    cursor: pointer;
`;

function Login() {
    const{email, setEmail, password, setPass} = useCredentialStore();
    const{setUser} = useUserStore();
    const navigate = useNavigate();
    const {activeCompany} = useCompanyStore();
    const {loading, setLoading, setTitle} = useInterfaceStore();
    const [error, setError] = useState(false);

    let endpoint = 'users/login';
    
    function handleChange(event) {
        setError(false);
        let name = event.target.name;
        if (name == 'email') {
            setEmail(event.target.value)
        } else if (name == 'password') {
            setPass(event.target.value);
        }
        const submit_button = event.target;
        if (email && password) {
            setDisabledButton(false);
        }

    }
    const [disabledButton, setDisabledButton] = React.useState(true);

    const handleSubmit = async (event) => {
        const data = {'email': email, 'password': password};
        event.preventDefault();
        let token = await Api.getData(endpoint, data).then( (token) => {
            Api.setToken(token.data.token, token.data.refresh_token);
            let initials = token.data.firstname.substring(0,2).toUpperCase();
            let user_data = {
                id: token.data.id,
                email:  token.data.email,
                firstname: token.data.firstname,
                lastname: token.data.lastname,
                last_login: token.data.last_login,
                initials: initials
            }
            setLoading();
            setUser(user_data);
            let new_location = nextStep.getNext();
            navigate(new_location);
        }).catch(async (error) => {
            console.log(error);
            if (error.response.status == 401) {
                toast.error('Gebruikersnaam of wachtwoord onjuist');
                // setError();
            }

        });
    }
    
    useEffect(() => {
        setTitle('Log in');
    }, []);
    
    
    return (
        <Wrapper>
        <div>
        <Form className="loginform"
        onSubmit={(event) => {
            event.preventDefault()
            handleSubmit(event);
        }}
        >
        <Input 
            onChange={(event) => {handleChange(event)}} 
            type="email" 
            id="email" 
            name="email"
            placeholder = "Email"
        />
        <Input 
            onChange={(event) => {handleChange(event)}} 
            type="password" 
            id="password" 
            name="password"
            placeholder="Password"
        />
        <AskButton disabled={disabledButton} type="submit">Inloggen</AskButton>
        {error !== false && <div className="error login-error fade-in"> {error}</div>}
        </Form>
        <Link onClick={(event) => navigate('/password-reset')}>Wachtwoord vergeten?</Link>
        </div>
        </Wrapper>
    );
}

export default Login
