import React, {useEffect, useState} from 'react';
import { toast } from 'react-toastify';
import Api from "../helpers/api";
import nextStep from "../helpers/nextStep";
import { setAuthToken } from "../helpers/setAuthToken"
import {useCredentialStore} from '../store';
import {useUserStore} from '../store';
import {useCompanyStore} from '../store';
import {useInterfaceStore} from '../store';
import { useNavigate, useParams } from "react-router-dom";
import sendIcon from '../assets/send-icon-min.svg';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Form = styled.form`
    padding-top: 12vh;
    width: 20vw;
    left: 50%;
    bottom: 50%;
    position: relative;
    z-index: 3;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.3));
    transform: translate(-50%,50%);
`;

const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  padding: 10px 40px;
  border-radius: 5em;
  border:none;
  margin-bottom: 1em;
  color: #2371CB;
  font-size: 18px;
  height: 54px;
  &::placeholder,
  &::-webkit-input-placeholder {
    color: #2371CB;
    opacity: 1;
  }
  :-ms-input-placeholder {
     color:  #2371CB;
     opacity: 1;
  }
  &:focus {
    outline: none !important;
  }
`;

const AskButton = styled.button`
    position: relative;
    display: block;
    background-color: #2371CB;
    color: #ffffff;
    background-image: url(${sendIcon});
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 17px 18px;
    width: 100%;
    height: 54px;
    border-radius: 3em;
    border: none;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
        filter: brightness(1.1);
    }
    &:disabled {
        filter: brightness(0.75);
    }
`;

const PassReset = () => {
    const {token} = useParams();
    const{email, setEmail, password, setPass} = useCredentialStore();
    const{setUser} = useUserStore();
    const navigate = useNavigate();
    const {activeCompany} = useCompanyStore();
    const {loading, setLoading} = useInterfaceStore();
    const [error, setError] = useState(false);
    const [message, setMessage] = useState(false);
    
    let endpoint = 'users/password-reset';

    function handleChange(event) {
        setError(false);
        let name = event.target.name;
        if (name == 'email') {
            setEmail(event.target.value)
        } else if (name == 'password') {
            setPass(event.target.value);
        }
        const submit_button = event.target;
        if (email || password) {
            setDisabledButton(false);
        }

    }
    const [disabledButton, setDisabledButton] = React.useState(true);

    const handleSubmit = async (event) => {
        const data = {'email': email, 'password': password};
        event.preventDefault();
        setDisabledButton(true);
        if (!token) {
            // first request email 
            let result = await Api.getData(endpoint, data).then( (response) => {
              // handle result 
                if (response.data.success) {
                    toast.success('We hebben zojuist een link naar '+email+' gestuurd om een nieuw wachtwoord te kiezen. ');
                }
            }).catch(async (error) => {
                console.log(error);
            })

        } else {
            data["token"] = token;
            let result = await Api.getData(endpoint, data).then( async (response) => {
                if (response.data.success) {
                    toast.success('Het is gelukt. Je kan voortaan inloggen met je nieuwe wachtwoord. Een moment geduld, je wordt ingelogd...' );
                    // try logging in. 
                    endpoint = 'users/login';
                    data["email"] = response.data.email;
                    let result = await Api.getData(endpoint, data).then( (token) => {
                        Api.setToken(token.data.token, token.data.refresh_token);
                        let initials = token.data.firstname.substring(0,2).toUpperCase();
                        let user_data = {
                            id: token.data.id,
                            email:  token.data.email,
                            firstname: token.data.firstname,
                            lastname: token.data.lastname,
                            last_login: token.data.last_login,
                            initials: initials
                        }
                        setLoading();
                        setUser(user_data);
                        let new_location = nextStep.getNext();
                        navigate(new_location);
                    }).catch(async (error) => {
                        if (error.response.status == 401) {
                            toast.error('Inlog niet gelukt');
                        }

                    });
                }

              // handle result  
            }).catch(async (error) => {
                console.log(error);
            })

        }
    }

    
    if (!token) {
        return (
            <Wrapper>
            <div>
            <Form className="loginform"
            onSubmit={(event) => {
                event.preventDefault()
                handleSubmit(event);
            }}>
            <Input 
                onChange={(event) => {handleChange(event)}} 
                type="email" 
                id="email" 
                name="email"
                placeholder = "Email"
            />
            <AskButton disabled={disabledButton} type="submit">Verzoek indienen</AskButton>
            {error !== false && <div className="error login-error fade-in"> {error}</div>}
            {message !== false && <div className="message login-message fade-in"> {message}</div>}
            </Form>
            </div>
            </Wrapper>
        );

    } else {

        return (
            <Wrapper>
            <div>
            <Form className="loginform"
            onSubmit={(event) => {
                event.preventDefault()
                handleSubmit(event);
            }}
            >
            <Input 
                onChange={(event) => {handleChange(event)}} 
                type="password" 
                id="password" 
                name="password"
                placeholder="Password"
            />
            <AskButton disabled={disabledButton} type="submit">Wachtwoord reset</AskButton>
            {error !== false && <div className="error login-error fade-in"> {error}</div>}
            </Form>
            </div>
            </Wrapper>
        );
    }
}

export default PassReset
