import React from 'react';
import axios from 'axios';

import { setAuthToken } from "../helpers/setAuthToken"
import {useCompanyStore, useLocationStore, useInterfaceStore} from '../store';

class Api {
    // set runtime cache and token
    static state = {
        token: null,
        data: null,
        busy: false, 
        queue: []
    };

        // http://api.gupp.test/
    // static base_url = 'http://159.65.193.25:5000/';
    static base_url = 'https://api.younifai.nl/';
    // static base_url = 'https://142.93.129.153:5000/';
    //
    // static base_url = 'http://aiaas.test/';

    static setToken = (token, refresh_token) => {
        this.state.token = token;
        setAuthToken(this.state.token);
        localStorage.setItem('token', token);
        if (refresh_token) {
            localStorage.setItem('refresh_token', refresh_token);
        }
    }
    
    static refreshToken = async () => {
        const refresh_token = localStorage.getItem('refresh_token');
        if (refresh_token.length > 0) {
            if (!this.state.busy) {
                this.state.busy = true;
                const endpoint = 'users/refresh_token';
                setAuthToken(refresh_token);
                return await this.getData(endpoint, [], 'post').then( (token) => {
                    Api.setToken(token.data.token);
    //                 useInterfaceStore.setState().loggedIn = true;
                    
                    this.state.busy = false;
                    return true;
                }).catch((error) => {
                    return false;
                });
            }
        }

        return false;
    }
    static hasRefreshToken = () => {
        const refresh_token = localStorage.getItem('refresh_token');
        if (refresh_token) {
            return true;
        } 
        return false;
    }

    static setAuthHeader = () => {
        setAuthToken(this.state.token);
    }
    static getConfig = () => {
        const activeCompany = useCompanyStore.getState().activeCompany;
        const activeIndexLocation = useLocationStore.getState().activeLocation;
        const config = {
            headers: {
                'ActiveCompany': activeCompany,
                'ActiveIndexLocation': activeIndexLocation
            }
        };
        return config;
    }
    static logOut = () => {
        if (localStorage.getItem('token')) {
            localStorage.setItem('token','');
            this.setToken('')
        } else {
            console.log('no token');
        }
    }

    static isLoggedIn = () => {
        if (localStorage.getItem('token')) {
            let token = localStorage.getItem('token');
            this.setToken(token);
        }

        if (this.state.token) {
            return true;
        } else {
            return false;
        }
    }
    
    static readFile = async (endpoint) => {
        const config = this.getConfig();
        config['responseType'] = 'arrayBuffer'
        return axios.get(this.base_url+endpoint, config);
    }

    static getData = async (endpoint, data, method) =>  {
       //  this.setAuthHeader();
        const config = this.getConfig();
        if (method == undefined) {
            method = 'post';
        }
        try {
            if (method == 'post') {
                return axios.post(this.base_url+endpoint, data, config);
            } else if (method == 'put') {
                return axios.put(this.base_url+endpoint, data, config);
            } else if (method == 'get') {
                return axios.get(this.base_url+endpoint, config);
            } else if (method == 'file') {
                config.headers["Content-Type"] = "multipart/form-data";
                return axios.post(this.base_url+endpoint, data, config);
            } else if (method == 'delete') {
                return axios.delete(this.base_url+endpoint, config);
            }
            /*
            .then( (response) => {
                console.log(response);
                return response;
            }, (error) => {
                console.log(error);
            }) ;
            */
            // .then(response => {
            //     return response.data;
            // })
        } catch( err) {
            console.log('deze', 'Api.getData')
        }
        // send request, get data
        // save data to runtime cache
        // return from cache else 
        // return from request
    };
}

export default Api;

