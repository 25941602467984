import React from 'react';
import styled from 'styled-components';
import sendIcon from '../assets/send-icon-min.svg';
import Api from '../helpers/api';
import LocationList from '../components/locationlist';

import {PageWrapper, SectionTitle, ListItemCel, ListItemLabel, Form, Input, AskButton } from '../ui_components/general';
import {useUserStore} from '../store';


export default function Dashboard() {

    const{id, firstname, setFirstname, lastname, setLastname} = useUserStore();
    const handleChange = async (event) => {
        let name = event.target.name;
        if (name == 'firstname') {
            setFirstname(event.target.value);
        } 
        if (name == 'lastname') {
        }
    }
    let endpoint = `users/${id}`;

    const handleSubmit = async (event) => {
        const data = {'firstname': firstname, 'lastname': lastname};
        event.preventDefault();
        let response = await Api.getData(endpoint, data, 'put').then( (response) => {
            console.log(response);
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <PageWrapper>
            <div>
                <SectionTitle>Persoonlijke gegevens</SectionTitle>
                <Form  onSubmit={(event) => {handleSubmit(event)}}>
                    <ListItemCel>
                        <ListItemLabel>Voornaam</ListItemLabel>
                        <Input
                            key="firstname_input"
                            onChange={(event) => setFirstname(event.target.value)}
                            name = "firstname"
                            value = {firstname}
                        />
                    </ListItemCel>
                    <ListItemCel>
                        <ListItemLabel>Achternaam</ListItemLabel>
                        <Input
                            key="lastname_input"
                            onChange={(event) => setLastname(event.target.value) } 
                            value = {lastname}
                            name = "lastname"
                        />
                    </ListItemCel>
                    <AskButton type="submit">Opslaan</AskButton>
                </Form>
                <SectionTitle>Kennis locaties</SectionTitle>
                <LocationList />
            </div>
        </PageWrapper>
    );
}
