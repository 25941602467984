import React from 'react';

import styled from 'styled-components';
import sendIcon from '../assets/send-icon-min.svg';
import arrowDown from '../assets/arrow-down-min.svg';

export const PageWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 100vh;
  background-color: var(--light-green);
  flex-wrap: wrap;
`;

export const PageCenterWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

export const PageSidebarWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
export const FourColGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    list-style: none;
    list-style-type: none;
    margin: 0;
    padding: 0;
`;

export const PageTitle = styled.h1`
    color: #4991DE;
    font-size: 38px;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    position: relative;
`;

export const SectionTitle = styled.h2`
    color: var(--you-purple-light);
    font-size: 24px;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    position: relative;
`;
export const AreaTitle = styled.h3`
    color: var(--you-purple-light);
    font-size: 18px;
    text-transform: uppercase;
    width: 100%;
    text-align: left;
    position: relative;
`;

export const ContainerTitle = styled.h3`
    color: #4991DE;
    font-size: 18px;
    text-transform: none;
    font-weight: regular;
    width: 100%;
    text-align: left;;
    position: relative;
`;

export const ListItem = styled.li`
    width: 100%;
    margin: 20px;
    display: flex;
    flex-wrap: nowrap;
`;

export const ListItemFit = styled.li`
    width: fit-content;
    margin: 20px;
    display: flex;
    flex-wrap: nowrap;
`;

export const ListFlexItem = styled.li`
    width: 100%;
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
`;


export const ListItemCel = styled.div`
    color: var(--text-color);
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;
/*
    &:nth-child(2),
    &:nth-child(1) {
        width: 15%;
    }
    &:nth-child(3) {
        width: 66%;
    }
    */

export const InputWrapper = styled.div`
    width: 100%;
    padding: 12px 20px;
    background-color: #ffffff;
    position: relative;


`;
export const ListItemLabel = styled.div`
    color: var(--you-purple-light);
    font-size: 16px;
`;

export const Form = styled.form`
    display: flex;
    padding: 20px;
    margin: 20px;
    position: relative;
    flex-wrap: wrap;
`;

export const Input = styled.input`
    width: 100%;
    box-sizing: border-box;
    border:none;
    border: 1px solid var(--you-purple-light);
    border-radius: 8px;
    font-size: 18px;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 5px 12px;
        &::placeholder,
        &::-webkit-input-placeholder {
            color: #2371CB;
            opacity: 1;
        }
        :-ms-input-placeholder {
             color:  #2371CB;
             opacity: 1;
        }
        &:focus {
            outline: none !important;
        }
`;

export const Textarea = styled.textarea `
    width: 100%;
    box-sizing: border-box;
    border:none;
    border: 1px solid var(--you-purple-light);
    border-radius: 8px;
    font-size: 18px;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 5px 12px;
        &::placeholder,
        &::-webkit-input-placeholder {
            color: #2371CB;
            opacity: 1;
        }
        :-ms-input-placeholder {
             color:  #2371CB;
             opacity: 1;
        }
        &:focus {
            outline: none !important;
        }
`;
/*
export const AskButton = styled.button`
    position: absolute;
    display: block;
    background-color: #ffffff;
    color: var(--you-green);
    background-image: url(${sendIcon});
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 17px 18px;
    width: fit-content;
    height: fit-content;
    border-radius: 5px;
    border: 1px solid var(--you-green);
    font-size: 14px;
    padding: 12px 35px 12px 24px;
    right: 0;
    bottom:0;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
    }
    &:disabled {
        cursor: not-allowed;
    }
    .submitted & {
    }
`;
*/

export const AskButton = styled.button`
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    padding: 12px 25px;
    border-radius: 4px;
    font-size: 20px;
    width: fit-content;
    height: fit-content;
    color: var(--you-purple);
    cursor: pointer;
    box-shadow: 0 0 4px var(--shadow-color);
    transition: all 0.3s ease-in-out;
    &:hover {
        box-shadow: 0 0 16px var(--shadow-color);
        background-color: rgba(255, 255, 255, 1);
    }
    &.align-right {
        margin-left: auto;
    }
    & + & {
        margin-left: 1ch;
    } 
`;

export const ListWrapper = styled.ul`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    list-style-type: none;
    margin: 0;
    padding: 0;
    justify-content: center;
`;

export const LeftColumn = styled.div`
    width: 20%;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;

`;

export const RightColumn = styled.div`
    box-sizing: border-box;
    width: 80%;
    border-left: 1px solid var(--you-green);
    padding-left: 35px;
    background-color: #ffffff;
`;

export const Collapse = styled.div`
    position: relative;
    width: 100%;
    & .collapse-content {
        height: 0;
        opacity: 0;
        overflow: hidden;
        transition: all 0.3s ease-out;
    }
    &.showContent .collapse-content {
        height: 100%;
        opacity: 1;
    }
`;
export const Dropdown = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
`;

export const DropdownList = styled.div`
    background-color: #ffffff;
    z-index: 9;
    color: var(--you-purple);
    font-size: 22px;
    max-height: 0;
    overflow: hidden;
    position: absolute;
    top: 3em;
    transition: all 0.3s ease-out;
    overflow-y: auto;
    height: auto;
    &.active {
    max-height: 30vh
    }
`;
export const DropdownListItem = styled.div`
    display: block;
    padding: 12px;
    border-bottom: 1px solid var(--you-purple);
    cursor: pointer;
    &:hover {
        color: var(--you-green);
    }
`;

export const DropdownToggle = styled.button`
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    background-color: transparent;
    background-image: url(${arrowDown});
    width: 35px;
    height: 35px;
    border: none;
    cursor: pointer;
`;
export const ModalContent = styled.div`
    min-height: 40vh;
    
`;

export const FormCol = styled.div`
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
`;
export const Toolbar = styled.div`
    padding: calc(var(--site-padding) / 2) var(--site-padding);
`;

export const ToolbarButton = styled.button`
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    padding: 12px 25px;
    border-radius: 4px;
    font-size: 20px;
    color: var(--you-purple);
    cursor: pointer;
    box-shadow: 0 0 4px var(--shadow-color);
    transition: all 0.3s ease-in-out;
    &:hover {
        box-shadow: 0 0 16px var(--shadow-color);
        background-color: rgba(255, 255, 255, 1);
    }
    &.align-right {
        margin-left: auto;
    }
    & + & {
        margin-left: 1ch;
    } 
    & + &.align-right {
        margin-left: auto;
        margin-right: 1ch;
    }
`;

export const InlineButton = styled.button`
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    padding: 8px 22px;
    border-radius: 4px;
    font-size: 16px;
    color: var(--you-purple);
    cursor: pointer;
    box-shadow: 0 0 4px var(--shadow-color);
    transition: all 0.3s ease-in-out;
    &:hover {
        box-shadow: 0 0 16px var(--shadow-color);
        background-color: rgba(255, 255, 255, 1);
    }
    & + & {
        margin-left: 1ch;
    }
    &.align-right {
        margin-left: auto;
    }
`;

export const ButtonsSection = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    margin-top: 35px;
`;

export const ButtonsEndSection = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    margin-top: 35px;
    justify-content: flex-end;
`
export const Cardgroup = styled.div`
    padding: calc(var(--site-padding) / 2) var(--site-padding);
    display: grid;
    grid-template-columns: repeat(auto-fit, 330px);
    column-gap: var(--site-padding);
    row-gap: var(--site-padding);
`;
export const SmallCard = styled.div`
    background-color: rgba(255, 255, 255, 0.5);
    padding: 20px;
    height: 130px;
    box-shadow: 0 0 4px var(--shadow-color);
    border-radius: 8px;
    transition: all 0.3s ease-out;
    cursor: pointer;
    &:hover {
        box-shadow: 0 0 16px var(--shadow-color);
        background-color: rgba(255, 255, 255, 0.8);
    }
    &.active {
        box-shadow: 0 0 24px var(--shadow-color);
        background-color: rgba(255, 255, 255, 1);
    }
`;
export const Cardtitle = styled.div`
    color: var(--you-purple);
    font-weight: 400;
`;

export const AreaWrapper = styled.div`
    padding: calc(var(--site-padding) / 2) var(--site-padding);
`;

export const SidebarLayout = styled.div`
      display: grid;
      grid-gap: var(--site-padding);
      grid-template-columns: minmax(300px, 450px) 1fr;
      padding: calc(var(--site-padding) / 2) var(--site-padding);
`;

export const Sidebar = styled.aside`
    display: grid;
    align-content: flex-start;
    row-gap: var(--site-padding);
`;

export const Workspace = styled.div`
    background-color: rgba(255, 255, 255, 0.5);
    padding: 20px;
    min-height: 130px;
    box-shadow: 0 0 4px var(--shadow-color);
    border-radius: 8px;
    transition: all 0.3s ease-out;
    cursor: pointer;
`;

export const Description = styled.div`
    margin: 0 1em;
    cursor: pointer;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
        &:active,
        &:focus {
          -webkit-line-clamp: unset;
        }
`;

export const ChatForm = styled.form`
    display: flex;
    margin: var(--site-padding) -20px 0;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    box-shadow: 0 0 4px var(--shadow-color);
    position: relative;
    flex-wrap: nowrap;
`;

