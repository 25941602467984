import React, {useEffect} from 'react';
import Api from '../helpers/api';
import {sleep} from '../helpers/helpers';
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";

import {promptStore} from '../store';
import {Prompt, PromptForm} from './prompt';

import {
    SectionTitle, 
    InlineButton
} from '../ui_components/general';
const Promptlist = styled.div`
    position: relative;
    flex-wrap: wrap;
    & .dark {
        max-width: 60vw;
    }
`;

export const PromptList = (className) => {

    const {prompts, addPrompts} = promptStore();
    const navigate = useNavigate();
    
    const loadPrompts = async () => {
        let endpoint = 'prompts/'
        let response = await Api.getData(endpoint, {}, 'get').then(
            (response) => {
                // locate prompts set prompts in store
                addPrompts(response.data);

            }).catch( async (error) => {
                if (error.response.status == 401) {
                    if (Api.state.busy) {
                        await sleep(400);
                        loadPrompts();
                    } else { 
                        let result = await Api.refreshToken().then((succes) => {
                            if (succes) {
                                // retry request
                                loadPrompts();
                            } else {
                                // navigate to login
                                navigate('/login');
                            }
                        }).catch((error) => {
                            // navigate to login
                            navigate('/login');
                        });
                    }
                }
            });
    }
    useEffect( () => {
        async function getPrompts() {
            await sleep(400);
            loadPrompts();
        }
        getPrompts();
    }, []);
    
    return (
        <ul className={className.className}>
            {/* nieuwe prompt toevoegen in modal*/}
            {prompts && 
                // console.log(prompts)
                prompts.map((prompt) => (
                 <PromptListItem key={prompt.id} prompt={prompt} />
                ))
            }
        </ul>
    )
}
const PromptListItem = ({prompt}) => {
    return (
        <li className="task-list-item">
            {prompt.name} 
            <span className="detail">{(prompt.default ? 'standaard ' : '')}</span>
            <span className="detail">scope: {prompt.scope}</span>
        {/*<InlineButton className="align-right">Aanpassen</InlineButton>*/}
        </li>
    )
}

