import React, {useEffect, useState} from 'react';
import Api from '../../helpers/api';
import {InputContainer} from './inputcontainer';

import {PageCenterWrapper, 
    FourColGrid,
    SectionTitle, 
    ListItem,
    ListItemCel, 
    ListItemLabel, 
    Input, 
    ListWrapper,
    Dropdown,
    DropdownList,
    DropdownListItem,
    DropdownToggle,
    ModalContent,
    ButtonsSection,
    Description,
    Form, 
    FormCol,
    AskButton,
    Textarea
} from '../../ui_components/general';


export const OutputForm = ({step, workflow_id}) => {
    let endpoint = `workflows/${workflow_id}/steps/`;
    const description = "In de output kun je aangeven op welke manier het systeem moet reageren. Je kan bijvoorbeeld gebruik maken van een communicatiestijl of een template die beschikbaar zijn binnen de organisatie. Geef ook aan op welke manier je wil dat je op de hoogte wordt gebracht."
    console.log(step);
    const handleSubmit = async (event) => {
        event.preventDefault();
        
        let data = {
            'name': name,
            'input': input,
            'instruction': instruction,
            'type': 'generate_pdf'
        };
        let response = await Api.getData(endpoint, data, 'post').then( (response) => {
            console.log(response);
            // 
        });
    };

    const handleChange = (event) => {
        if (event.target.name == 'name') {
            setName(event.target.value);
        }
        if (event.target.name == 'instruction') {
            setInstruction(event.target.value);
        }
        if (event.target.name == 'notification') {
            setNotification(event.target.value);
        }
        setDisabledButton(false)
    }

    const defaultName = (step ? step.name : '');
    const defaultInput = (step ? step.input : '');
    const defaultInstruction = (step ? step.instruction : '');
    const defaultNotification = (step ? step.notification : '');
    const [name, setName] = React.useState(defaultName);
    const [input, setInput] = React.useState(defaultInput);
    const [notification, setNotification] = React.useState(defaultInput);
    const [instruction, setInstruction] = React.useState(defaultInstruction);

    const [disabledButton, setDisabledButton] = React.useState(true);
    return (
        <>
        <Form id="prompt_step_form" autoComplete="off" onSubmit={(event) => { handleSubmit(event)}}>
            <FormCol>
            <Description>{description}</Description>
            <ListItem>
                <ListItemCel>
                    <ListItemLabel>Naam</ListItemLabel>
                    <Input 
                        onChange= {(event) => handleChange(event)}
                        onFocusOut = {(event) => handleChange(event)}
                        name="name" 
                        value= {name}
                    />
                </ListItemCel>
            </ListItem>
            <ListItem>
                <ListItemCel>
                    <ListItemLabel>Email adres</ListItemLabel>
                    <Input 
                        onChange= {(event) => handleChange(event)}
                        onFocusOut = {(event) => handleChange(event)}
                        name="notification" 
                        value= {notification}
                    />
                </ListItemCel>
            </ListItem>
            <ListItem>
            <ListItemCel>
                <ListItemLabel>Instructie</ListItemLabel>
                <Textarea
                    rows="5"
                    cols = "50"
                    onChange= {(event) => handleChange(event)}
                    onFocusOut = {(event) => handleChange(event)}
                    name="instruction" 
                    value= {instruction}
                />
            </ListItemCel>
            </ListItem>
            </FormCol>
            <FormCol>
            <ListItem>
            <ListItemCel>
                <ListItemLabel>Inputs toevoegen</ListItemLabel>
                <InputContainer input={input} setInput={setInput} setDisabledButton={setDisabledButton} />
            </ListItemCel>
            </ListItem>
            </FormCol>
        </Form>
        <ButtonsSection>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <AskButton className="align-right" form="prompt_step_form" disabled={disabledButton} type="submit">{(prompt ? 'Opslaan' : 'Toevoegen')}</AskButton>
        </ButtonsSection>
        </>
    )
}
