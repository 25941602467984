import React from 'react';
import styled from 'styled-components';

import LocationList from '../components/locationlist';
import CompanyPicker from '../components/companypicker';
import closeIcon from '../assets/close_icon-min.svg';

import {useInterfaceStore} from '../store';

const Closebutton = styled.button`
    background-color: transparent;
    width: 35px;
    border: none;
    height: 35px;
    color: #00DFA3;
    background-image: url(${closeIcon});
    background-repeat: no-repeat;
    background-position: center;
    margin: 12px;
`;

const Drawer = styled.div`
    position: fixed;
    top: 0;
    width: 0;
    right: -6px;
    background-color: #ffffff;
    border-left: 12px solid var(--you-green);
    transition: all 0.4s ease-out;
    height: 100%;
    z-index: 8;
    &.active {
        width: 60vw;
    }
`;
function OptionDrawer() {
    const {showDrawer, openDrawer, closeDrawer } = useInterfaceStore();
    let showClass = '';
    if (showDrawer) {
        showClass = 'active'
    }
    return (
        <Drawer className={showClass}>
            <Closebutton onClick={(event) => {closeDrawer()}} />    
            <CompanyPicker />
            <LocationList className="dark" />
        {
            // show active company
            // link to choose company
            // link to activeate index
            //
        }
        </Drawer>
    )
}

export default OptionDrawer;
