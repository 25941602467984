import {$getRoot, $getSelection} from 'lexical';
import {useEffect, useState} from 'react';

import {AutoFocusPlugin} from '@lexical/react/LexicalAutoFocusPlugin';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {LexicalErrorBoundary} from '@lexical/react/LexicalErrorBoundary';

/* extended usecase */
/* @TODO move all toolbar images into assest and load them inline same as LOGO */
import ExampleTheme from "./ExampleTheme";
import ToolbarPlugin from "./ToolbarPlugin";
import {$generateNodesFromDOM} from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ListNode, ListItemNode } from "@lexical/list";
import { HeadingNode } from "@lexical/rich-text";


const theme = {
  // Theme styling goes here
  //...
}

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error) {
  console.error(error);
}

export const Editor = ({ html_string })  => {
    const LoadInitialContent = ({ initialContent }) => {
        const [editor] = useLexicalComposerContext();
        const [contentLoaded, setContentLoaded] = useState(false)
        useEffect(() => {
            if (!initialContent) { return; }
            if (contentLoaded) {return ; }
            setContentLoaded(true);
            editor.update(() => {
                const parser = new DOMParser();
                const dom = parser.parseFromString(initialContent, "text/html");
                const nodes = $generateNodesFromDOM(editor, dom);
                // $getRoot().select();
                const root = $getRoot();
                // $insertNodes(nodes);
                root.clear();
                root.append(...nodes);
            });
        }, [html_string]);
        return null;
    };



    const initialConfig = {
        namespace: 'MyEditor',
        theme: ExampleTheme,
        onError,
        nodes: [ListNode, ListItemNode, HeadingNode]
    };

    return (
        <LexicalComposer initialConfig={initialConfig}>
        { /* <ToolbarPlugin /> */ }
             <div className="editor-container">
                <LoadInitialContent initialContent={html_string} />
                 <div className="editor-inner">
                    <RichTextPlugin
                        contentEditable={<ContentEditable />}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                </div>
                <HistoryPlugin />
                <AutoFocusPlugin />
            </div>
        </LexicalComposer>
    );
}

