import React, {useEffect, useState} from 'react';
import Api from "../helpers/api";

import { useNavigate, useParams } from "react-router-dom";
import {useCompanyStore, useInterfaceStore, modalStore, useLocationStore} from '../store';

import {PromptModal} from '../components/prompt';
import {IndexLocationForm} from '../components/indexlocation'
import {PromptList} from '../components/promptlist';
import {LocationList} from '../components/locationlist';
import {WorkflowList} from '../components/workflowlist';
import {WorkflowModal} from '../components/workflowmodal';

import {CompanyInfo} from '../components/company';

import {PageCenterWrapper, 
    FourColGrid,
    SectionTitle, 
    ListItemCel, 
    ListItemLabel, 
    Form, 
    Input, 
    AskButton,
    ListWrapper,
    Cardgroup, 
    SmallCard, 
    Cardtitle,
    AreaWrapper,
    Workspace,
    Toolbar,
    ToolbarButton,
    ButtonsSection
} from '../ui_components/general';


const Company = () => {
    const {companies, addCompany, activeCompany, setActiveCompany, getCompanyName} = useCompanyStore();
    const {setTitle} = useInterfaceStore();
    const { setModalState } = modalStore(); 
    const navigate = useNavigate();
    const activeCompanyId = useParams().id;
    const [company, setCompany] = useState({});
    const getActiveCompany = async () => {

        if (!companies 
            || Object.keys(companies).length < 1
        ) {
            let endpoint = 'companies/';
            let data = {};
            let response = await Api.getData(endpoint, data, 'get').then( (response) => {
                let data_companies = response.data;
                data_companies.forEach(function (i_company) {
                    addCompany(i_company);
                    if (i_company.id == activeCompanyId) {
                        setCompany(i_company);
                    }
                });

                setActiveCompany(activeCompanyId);
                setTitle(companies[activeCompanyId].name);

            }).catch(async (error) => {
                if (error.response.status == 401) {
                    let result = await Api.refreshToken().then((succes) => {
                        if (succes) {
                            // retry request
                            getActiveCompany();
                        } else {
                            // navigate to login
                            navigate('/login');
                        }
                    }).catch((error) => {
                        // navigate to login
                        navigate('/login');
                    });
                }
            });
        } else {
            if (companies[activeCompanyId]) {
                setCompany(companies[activeCompanyId]);
                setTitle(companies[activeCompanyId].name)
            }
            /*
            companies.forEach(function(i_company) {
                if (i_company.id == activeCompanyId) {
                    setCompany(i_company);
                    setTitle(i_company.name);
                    return;
                }
            });
            */
            setActiveCompany(activeCompanyId);
        }
    }

    const {indexLocations, addLocation } = useLocationStore();
    const { loading } = useInterfaceStore();

    const getIndexLocations = async () => {
        let endpoint = 'index_locations';
        let data = {};
        let response = await Api.getData(endpoint, data, 'get').then( (response) => {
            let locations = response.data;
            locations.forEach(function (index_location) {
                addLocation(index_location);
            });

        }).catch( async (error) => {
            if (error.response.status == 401) {
                let result = await Api.refreshToken().then((result) => {
                    if (result) {
                        // retry request
                        getIndexLocations();
                    } else {
                        // navigate to login
                         navigate('/login');
                    }
                }).catch((error) => {
                    // navigate to login
                     navigate('/login');
                });
            }
        });

    }
    

    const showWorkflowModal = async () => {
            setModalState({
                title: "Workflow toevoegen",
                modalContent: <WorkflowModal />,
                showModal: true,
                size: "large"
            });

    }

    const showPromptModal = async () => {
        setModalState(
            {
                title: "Communicatie stijl toevoegen",
                modalContent: <PromptModal />,
                showModal: true,
                size: "large"
                
            }
        )
    }
    const showIndexFormModal = async () => {
        setModalState(
            {
                title: "Kennisgebiedt toevoegen",
                modalContent: <IndexLocationForm />,
                showModal: true,
                size: "large"
            }
        )
    }

    useEffect( () => {
        async function getData() {
            await getActiveCompany().then(async () => { 
               //  await getIndexLocations();
            }
            );
        }
        getData();
    }, []);

    const [workspaceType, setWorkspaceType] = useState('workflows');

    const renderWorkspace = ({workspaceType}) => {
        // determine what wqorkspace to render
        if (workspaceType == 'settings') {
            return <CompanyInfo company={companies[activeCompanyId]} />
        } else if (workspaceType == 'prompts') {
            return <PromptList />
        } else if (workspaceType == 'workflows') {
            return <WorkflowList company={activeCompany} />
        } else if (workspaceType == 'indexes') {
            return <>
                    <ToolbarButton onClick={(event) => {showIndexFormModal()}}>Kennisgebied toevoegen</ToolbarButton>
                    <LocationList />
                </>
        }
    }


    return (
        <>
        <Toolbar>
            <ToolbarButton onClick={(event) => navigate('/companies')}>Werkgebieden overzicht</ToolbarButton>
            <ToolbarButton onClick={(event) => navigate('/chat')}>Chat</ToolbarButton>
            <ToolbarButton onClick={(event) => showPromptModal()}>Prompt toevoegen</ToolbarButton>
            <ToolbarButton onClick={(event) => showWorkflowModal()}>Workflow toevoegen</ToolbarButton>
        </Toolbar>
        <Cardgroup>
            <SmallCard className={(workspaceType == 'settings' ? 'active' : '')} onClick={(event) => {setWorkspaceType('settings')}} >
                <Cardtitle>Settings</Cardtitle>
            </SmallCard>
            <SmallCard className={(workspaceType == 'prompts' ? 'active' : '')} onClick={(event) => {setWorkspaceType('prompts')}} >
                <Cardtitle>Communicatiestijlen</Cardtitle>
            </SmallCard>
            <SmallCard className={(workspaceType == 'indexes' ? 'active' : '')} onClick={(event) => {setWorkspaceType('indexes')}} >
                <Cardtitle>Kennisgebieden</Cardtitle>
            </SmallCard>
            <SmallCard className={(workspaceType == 'workflows' ? 'active' : '')} onClick={(event) => {setWorkspaceType('workflows')}} >
                <Cardtitle>Workflows</Cardtitle>
            </SmallCard>
        </Cardgroup>
        <AreaWrapper>
            <Workspace>
                {renderWorkspace({workspaceType})}
            </Workspace>
        </AreaWrapper>
        </>
    );
}

export default Company;
