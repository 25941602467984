import React from 'react';

import {PageWrapper, PageTitle, SectionTitle, ListItemCel, ListItemLabel, Form, Input, AskButton } from '../ui_components/general';
import {useLocationStore} from '../store';
import {FilesList} from '../components/fileslist';
import {IndexLocationForm} from '../components/indexlocation';
export default function FilesOverview() {
    // @TODO Kennisgebied wisselen 
    // @TODO bedrijf tonen
    const {activeLocationName, indexLocations, activeLocation} = useLocationStore();
    let active_location; 
    indexLocations.forEach(function(index_location) {
        if (index_location.id == activeLocation) {
            active_location = index_location;
        }
    });
    if (!active_location 
        || !active_location.type ) {
        return (
            <PageWrapper>
                <div>
                    <PageTitle>Kennisgebied: {activeLocationName}</PageTitle>
                    <div> Geen type bekend</div>
                </div>
            </PageWrapper>
        )
    } else if (active_location.type == 'documents') {
        return (
            <PageWrapper>
                <div>
                    <PageTitle>Kennisgebied: {activeLocationName}</PageTitle>
                    <FilesList />
                </div>
            </PageWrapper>
        )
    } else if (active_location.type == 'url') {
        return (
            <PageWrapper>
                <div>
                    <PageTitle>Kennisgebied: {activeLocationName}</PageTitle>
                    <IndexLocationForm active_location={active_location} />
                </div>
            </PageWrapper>
        )
        
    } 

}
