import React from 'react';
import styled from 'styled-components';
import Api from "../helpers/api";

import arrowDown from '../assets/arrow-down-min.svg';
import {useCompanyStore, useLocationStore} from '../store';
import {useState} from 'react';
import { useNavigate } from "react-router-dom";

import {PageWrapper,
    SectionTitle, 
    ListWrapper,
    ListItem,
    ListItemCel, 
    ListItemLabel, 
    Form, 
    Input, 
    AskButton,
} from '../ui_components/general';

const ToggleButton = styled.button`
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    background-color: transparent;
    background-image: url(${arrowDown});
    width: 35px;
    height: 35px;
    border: none;
    cursor: pointer;
`;

const OverviewButton = styled.a`
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    background-color: transparent;
    background-image: url(${arrowDown});
    width: 35px;
    height: 35px;
    transform: rotateZ(-120deg);
    border: none;
    cursor: pointer;
    left: 40px;
`;
const CompanyList = styled.div`
    background-color: #ffffff;
    z-index: 9;
    color: var(--you-purple);
    font-size: 22px;
    max-height: 0;
    overflow: hidden;
    position: absolute;
    top: 3em;
    transition: all 0.3s ease-out;
    overflow-y: auto;
    height: auto;
    &.active {
    max-height: 30vh
    }
`;
const CompanyItem = styled.div`
    display: block;
    padding: 12px;
    border-bottom: 1px solid var(--you-purple);
    cursor: pointer;
    &:hover {
        color: var(--you-green);
    }
`;
const DropDown = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
`;

const CompanyPicker = () => {
    const navigate = useNavigate();

    const {companies, setActiveCompany, getCompanyName, activeCompany } = useCompanyStore();
    const {clearLocations, indexLocations, addLocation} = useLocationStore();
    const [showList, setShowList] = useState(false);

    const handleCompanySwitch = async (company_id) => {
        setActiveCompany(company_id);
        clearLocations();
        getIndexLocations();  
        setShowList(false);

    }

    const getIndexLocations = async () => {
        let endpoint = 'index_locations';
        let data = {};
        let response = await Api.getData(endpoint, data, 'get').then( (response) => {
            let locations = response.data;
            locations.forEach(function (index_location) {
                addLocation(index_location);
            });

        }).catch( async (error) => {
            if (error.response.status == 401) {
                let result = await Api.refreshToken().then((result) => {
                    if (result) {
                        // retry request
                        getIndexLocations();
                    } else {
                        // navigate to login
                         navigate('/login');
                    }
                }).catch((error) => {
                    // navigate to login
                     navigate('/login');
                });
            }
        });
    }

    const toggleCompanyList = () => {
        if(showList) {
            setShowList(false);
        } else {
            setShowList(true);
        }
    }

    if (companies.length > 0) {

        return (
            <DropDown>
                <SectionTitle>Bedrijf: {getCompanyName}<span style={{position:'relative'}}><ToggleButton onClick={(event) => {toggleCompanyList()}}></ToggleButton><OverviewButton onClick={(event) => {navigate('/companies')}}></OverviewButton></span></SectionTitle>
                
              <CompanyList className={(showList ? 'active' : '')}>
                {companies && companies.map((company) => (
                    <CompanyItem onClick={(event) => {handleCompanySwitch(company.id)}} key={company.id}>{company.name}</CompanyItem>
                ))}
              </CompanyList>
            </DropDown>
        )
    } else {
        return (
            <a > kies een bedrijf</a>
        )
    }

}

export default CompanyPicker;
