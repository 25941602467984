import React, {useEffect, useState} from 'react';

import Api from "../../helpers/api";
import {IteratorForm} from "./iteratorstepform";
import {PromptForm} from "./promptstepform";
import {MapsiteForm} from "./mapsitestepform";
import {OutputForm} from "./outputstepform";

export const EditStepForm = ({step}) => {
    console.log({step});
    const [input, setInput] = React.useState([]);
    const [disabledButton, setDisabledButton] = React.useState(true);
    // p[repare situation where step already exists
    const renderForm = ({step}) => {
        console.log(step);
        let type = step.type;
        if (type == "iterator") {
            return <IteratorForm step={step} workflow_id={step.workflow_id} />
        } else if (type == "prompt") {
            return <PromptForm step={step} workflow_id={step.workflow_id} />
        } else if (type == "map_site") {
            return <MapsiteForm  step={step} workflow_id={step.workflow_id} />
        } else if (type == "generate_pdf") {
            return <OutputForm step={step}  workflow_id={step.workflow_id} />
        }
    }

    return (
        <div>
            {renderForm({step})}
        </div>
    )
}
