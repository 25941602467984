import React, {useEffect, useState} from 'react';

import Api from "../../helpers/api";
import {IteratorForm} from "./iteratorstepform";
import {PromptForm} from "./promptstepform";
import {MapsiteForm} from "./mapsitestepform";
import {OutputForm} from "./outputstepform";

export const AddStepForm = ({type, workflow_id}) => {
    
    const [input, setInput] = React.useState([]);
    const [disabledButton, setDisabledButton] = React.useState(true);
    // p[repare situation where step already exists
    const renderForm = ({type}) => {
        if (type == "iterator") {
            return <IteratorForm  workflow_id={workflow_id} />
        } else if (type == "prompt") {
            return <PromptForm  workflow_id={workflow_id} />
        } else if (type == "map_site") {
            return <MapsiteForm  workflow_id={workflow_id} />
        } else if (type == "output") {
            return <OutputForm  workflow_id={workflow_id} />
        }
    }

    return (
        <div>
            {renderForm({type})}
        </div>
    )
}
