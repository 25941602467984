import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import sendIcon from '../assets/send-icon-min.svg';
import Api from '../helpers/api';
import AnswerList from '../components/answerlist';
import { useNavigate } from "react-router-dom";
import {useStore, useAnswerStore} from '../store';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #13396D;
`;

const Form = styled.form`
    position: fixed;
    bottom: 20vh;
    width: 56vw;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.3));
`;

const Input = styled.input`
  font-size: 2em;
  width: 100%;
  padding: 10px 40px;
  border-radius: 5em;
  border:none;
  color: #2371CB;
  font-size: 18px;
  height: 55px;
  &::placeholder,
  &::-webkit-input-placeholder {
    color: #2371CB;
    opacity: 1;
  }
  :-ms-input-placeholder {
     color:  #2371CB;
     opacity: 1;
  }
  &:focus {
    outline: none !important;
  }
`;

const AskButton = styled.button`
    position: absolute;
    right: -21px;
    bottom: 50%;
    transform: translateY(50%);
    background-color: transparent;
    background-image: url(${sendIcon});
    width: 21px;
    height: 18px;
    border: none;
    cursor: pointer;
    .submitted & {
        opacity: 0.3;
        pointer-events: none;
        cursor: not-allowed;
    }
`;


const HomePage = () => {
    const { input, setInput } = useStore();
    const {answers, addAnswer} = useAnswerStore();

    const [submitted, setSubmitted] = useState(false);
    const [answerScroll, setAnswerScroll] = useState(0);

    const navigate = useNavigate();

    const handleChange = async (event) => {
        event.preventDefault();
        setSubmitted(true);
        let endpoint = 'questions/ask';
        let question = event.target.question.value;
        const question_data = {'question': question};
        try {
            let answer = await Api.getData(endpoint, question_data).then( (answer) => {
                addAnswer(answer.data);
            });
        } catch (err) {
            if (err.response == undefined) {
                // show unknown error
                console.log(err);
            } else if (err.response.status == 401) {
                Api.logOut();
                navigate('/login');
            } else {
                // show specific status code errors
            }
        }
        event.target.question.value = '';
        setSubmitted(false);
    }

    return (
        <Wrapper>
            <div>
                <Form className={(submitted ? "submitted" : '')} onSubmit={(event) => {handleChange(event)}}>
                    <Input
                        name = "question"
                        placeholder = "Verstuur jouw bericht"
                    />
                    <AskButton type="submit"></AskButton>
                </Form>
                <div className="anwerlist-container">
                    <AnswerList />     
                    <div className="space-saver"></div>
                </div>
            </div>
        </Wrapper>
    );
};

export default HomePage;
